import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, urlNavigate, getUrlPath, spinner } from '../components/function';
import Loading from '../pages/Loading';

let url = getUrlPath();

export default class InstallLogPage extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            page_load:true,
            install_log:{geo:{"name":"Georgia","img":"http://localhost:8080/uploads/geo/Georgia.png","code":"GE"}}
        }
    }
  
    componentDidMount(){
        this.GetData();
    }

    GetData = () => {
        
        ApiReq('/installs_log/get_data/', {select:["id", "ip", "install_naming", "install_organic_url", "geo", "device_id", "dt", "reg", "dep", "type", "link", "dop_log", "gaid", "ansver", "user_agent", "install_data"], where:{id:url[1]}, limit:[0,1]}).then(res => {
            if(res != false){
                if(res.success == true){
                    res.data[0].install_data = JSON.parse(res.data[0].install_data);
                    this.setState({
                        page_load:false,
                        install_log:res.data[0]
                    })
                }
                else{
                    notySend('error', 'error #'+res.error);
                }
            }
        })
    }

    
  
    render() {
        if(this.state.page_load){
            return(
                <div className='page-content'>
                    <div className="card">
                        <div className='card-body'>
                            <div className='text-center'><Loading></Loading></div>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return (
                <div className='page-content'>
                    <div className="card">
                        <div className='card-body'>
                            <div className='table-responsive' style={{padding:2}}>
                                <table className="table table-bordered  table-hover ">
                                    <tbody>
                                        <tr>
                                            <td style={{fontWeight:'bold'}}>Install id</td>
                                            <td>{this.state.install_log.id}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'bold'}}>Date (UTC)</td>
                                            <td>{this.state.install_log.dt}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'bold'}}>Device ID</td>
                                            <td>{this.state.install_log.device_id}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'bold'}}>Reg</td>
                                            <td>{this.state.install_log.reg}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'bold'}}>Dep</td>
                                            <td>{this.state.install_log.dep}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'bold'}}>Type</td>
                                            <td>{this.state.install_log.type}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'bold'}}>Ip</td>
                                            <td>{this.state.install_log.ip}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'bold'}}>Gaid</td>
                                            <td>{this.state.install_log.gaid}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'bold'}}>Geo</td>
                                            <td><img src={this.state.install_log.geo.img} width="25" height="25" style={{borderRadius:15}}></img> {this.state.install_log.geo.name} {'('+this.state.install_log.geo.code+')'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'bold'}}>Send url</td>
                                            <td>{this.state.install_log.link}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'bold'}}>User Agent</td>
                                            <td>{this.state.install_log.user_agent}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'bold'}}>Dop Log</td>
                                            <td>{this.state.install_log.dop_log}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'bold'}}>Install naming</td>
                                            <td>{this.state.install_log.install_naming}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'bold'}}>Install organic url</td>
                                            <td>{this.state.install_log.install_organic_url}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'bold'}}>data</td>
                                            <td><pre>{JSON.stringify(this.state.install_log.install_data, undefined, 4)}</pre></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

