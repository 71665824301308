import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, getRandomColor, getUrlPath, spinner, convertUTCTimeToLocalTime } from '../components/function';
import Loading from '../pages/Loading';
import CustomModal from '../components/CustomModal';
import GeoSelectList from '../components/GeoListSelect';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title,);

let url = getUrlPath();

export default class InstallLogsPage extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            list_table:[],
            page_load:false,
            btn_load:true,
            btn_load_more:false,
            btn_load_more_show:false,
            filter_dt_from:'',
            filter_dt_to:'',
            filter_geo:'',
            filter_naming:'',
            filter_customer:'',
            filter_limit:[0, 20],
            iframe_modal:false,
            iframe_url:'',

            chart_collapse_show:false,
            chart_show:false,
            chart_data:{labels: [], datasets: [{label: '# of Votes',data: [],backgroundColor: [],borderWidth: 1,},]},
            list_char_data:[],
            list_table_total_rows:0
        }
    }
  
    componentDidMount(){
        console.log(convertUTCTimeToLocalTime('2024-07-01 10:57:18'))
        this.GetData(1);
    }

    GetData = (type:Number) => {
        if(type === 1){
            this.setState({
                btn_load:true,
                page_load:false,
                filter_limit:[0, 20],
                list_table_total_rows:0
            })
        }
        else{
            this.setState({
                btn_load_more:true
            })
        }

        let where:any = {app:url[1]};
        if(this.state.filter_dt_from != ''){
            where.dt_from = this.state.filter_dt_from;
        }
        if(this.state.filter_dt_to != ''){
            where.dt_to = this.state.filter_dt_to;
        }
        if(this.state.filter_geo != ''){
            where.geo = this.state.filter_geo;
        }
        if(this.state.filter_naming != ''){
            where.install_naming = this.state.filter_naming;
        }
        if(this.state.filter_customer != ''){
            where.customer = this.state.filter_customer;
        }
       
        ApiReq('/installs_log/get_data/', {select:["id", "ip", "install_naming", "geo", "device_id", "dt"], where:where, limit:this.state.filter_limit}).then(res => {
            if(res != false){
                if(res.success == true){
                    if(type === 1){
                        this.setState({
                            list_table:res.data,
                            btn_load:false,
                            page_load:true,
                            btn_load_more:false,
                            list_table_total_rows:res.total_rows
                        })
                    }
                    else{
                        let list_table = this.state.list_table;
                        for(let i=0; i<res.data.length; i++){
                            list_table.push(res.data[i])
                        }
                        this.setState({
                            list_table:list_table,
                            btn_load:false,
                            page_load:true,
                            btn_load_more:false,
                            list_table_total_rows:res.total_rows
                        })
                    }
                    
                    if(res.data.length === this.state.filter_limit[1]){
                        this.setState({
                            btn_load_more_show:true,
                        })
                    }
                    else{
                        this.setState({
                            btn_load_more_show:false,
                        })
                    }
                }
                else{
                    notySend('error', 'error #'+res.error+'\n');
                }
            }
        })
    }

    GetTotalByGeo = () => {
        ApiReq('/installs_log/get_total_installs_by_geo/', {app:url[1]}).then(res => {
            if(res != false){
                if(res.success == true){
                    let list_char_data:any = [];
                    let chart_data:any = {labels: [], datasets: [{label: '',data: [],backgroundColor: [],borderWidth: 1}]};
                    for(let i=0; i<res.data.length; i++){
                        chart_data.labels.push(res.data[i].geo_name);
                        chart_data.datasets[0].data.push(res.data[i].quantity);
                        chart_data.datasets[0].backgroundColor.push(getRandomColor());
                    }
                    console.log(res.data)
                    this.setState({
                        chart_show:true,
                        chart_data:chart_data,
                        list_char_data:this.sortArrayDescendingByParameter(res.data, 'quantity')
                    })
                }
                else{
                    notySend('error', 'error #'+res.error+'\n');
                }
            }
        })
    }

    sortArrayDescendingByParameter<T extends Record<string, string | number>>(arr: T[], parameter: keyof T): T[] {
        return arr.sort((a, b) => {
          const aValue = a[parameter];
          const bValue = b[parameter];
      
          // Преобразование строки в число, если это необходимо, для обеспечения корректной сортировки чисел
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return parseFloat(bValue) - parseFloat(aValue);
          } else if (typeof aValue === 'number' && typeof bValue === 'number') {
            return bValue - aValue;
          } else {
            return 0;
          }
        });
    }

    RenderTable = () => {
        if(!this.state.page_load){
            return(
                <div className='text-center'><Loading></Loading></div>
            )
        }
        else{
            return(
                <div className='table-responsive' style={{padding:2}}>
                    <table className='table table-hover table-bordered text-center'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Geo</th>
                                <th>Ip</th>
                                <th>Device id</th>
                                <th>Campaign (naming)</th>
                                <th>Show</th>
                            </tr>
                        </thead>
                        <tbody style={{fontSize:12}}>
                            {this.state.list_table.map((item:any) => {
                                return (
                                    <tr key={item.id}>
                                        <td>{this.state.list_table.indexOf(item) + 1}</td>
                                        <td>{convertUTCTimeToLocalTime(item.dt)}</td>
                                        <td><img src={item.geo.img} width="25" height="25" style={{borderRadius:15}}></img> {item.geo.name + ' ('+item.geo.code+')'}</td>
                                        <td>{item.ip}</td>
                                        <td>{item.device_id}</td>
                                        <td>{(item.install_naming != '')?item.install_naming:'(not set)'}</td>
                                        <td> <span onClick={()=>{this.setState({iframe_modal:true, iframe_url:'/install_log/'+item.id+'/'})}}><i className="material-icons remove_red_eye c_p text-primary">&#xe417;</i></span> <a href={'/install_log/'+item.id+'/'}><i className="material-icons arrow_circle_up text-primary" style={{rotate:'90deg'}}></i></a></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <br></br>
                    {this.state.btn_load_more_show?<div className='text-center'><button disabled={this.state.btn_load_more} onClick={()=>{this.setState({filter_limit:[this.state.filter_limit[0]+20, this.state.filter_limit[1]]}); setTimeout(()=>{this.GetData(2)}, 200)}} style={{borderRadius:20}} className='btn btn-outline-primary'> {(this.state.btn_load_more) ? spinner(): null} load more</button></div>:null}
                    <br></br>
                </div>
            )
        }
    }
  
    render() {
        return (
            <div className='page-content'>
                <div className="card" style={{minHeight:400}}>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col'>
                                <label>Date from</label>
                                <input onInput={(t)=>{this.setState({filter_dt_from:t.currentTarget.value.replace('T', ' ')})}} className='form-control' type='datetime-local'></input>
                            </div>
                            <div className='col'>
                                <label>Date to</label>
                                <input onInput={(t)=>{this.setState({filter_dt_to:t.currentTarget.value.replace('T', ' ')})}} className='form-control' type='datetime-local'></input>
                            </div>
                            <div className='col'>
                                <label>Geo</label>
                                <GeoSelectList onChange={(t:any)=>{this.setState({filter_geo:t.value})}}></GeoSelectList>
                            </div>
                            <div className='col'>
                                <label>Naming</label>
                                <input onInput={(t)=>{this.setState({filter_naming:t.currentTarget.value})}} placeholder='Write full naming or main sub' className='form-control'></input>
                            </div>
                            <div className='col'>
                                <label>Customer</label>
                                <input onInput={(t)=>{this.setState({filter_customer:t.currentTarget.value})}} className='form-control'></input>
                            </div>
                            <div className='col'>
                                <label>Get report</label>
                                <button disabled={this.state.btn_load} onClick={()=>{this.GetData(1)}} className='btn btn-primary form-control'>{this.state.btn_load?spinner():null} <i style={{fontSize:18}} className="material-icons filter_list_alt">&#xe94e;</i> Get report</button>
                            </div>
                        </div>
                        <hr></hr>
                        
                        <div className="card">
                            <div className="card-header c_p" style={{borderBottom:'none'}} onClick={()=>{this.setState({chart_collapse_show:!this.state.chart_collapse_show}); if(this.state.list_char_data.length == 0){this.GetTotalByGeo();}}}>
                                <span className="card-link" data-toggle="collapse" >
                                    <div className='row'>
                                        <div className='col-8'><strong className='text-primary'>Analytics total installs by geo</strong></div>
                                        <div className='col-4' style={{textAlign:'right'}}><strong>Total rows {this.state.list_table_total_rows}</strong></div>
                                    </div>
                                </span>
                            </div>
                            <div  className={this.state.chart_collapse_show?'collapse show':'collapse'}>
                                <div className='card-body'>
                                    {this.state.chart_show?
                                        <div className='row'>
                                            <div className='col-2' style={{textAlign:'left'}}>
                                                <Pie options={{plugins:{title: {display: false,},legend: {display: false}}}} style={{width:'100%', maxHeight:150}} data={this.state.chart_data} />
                                            </div>
                                            <div className='col-10'>
                                                <div className="d-flex flex-wrap ">
                                                    {this.state.list_char_data.map((item:any) => {
                                                        if(this.state.list_char_data.indexOf(item) < 20){
                                                            return (
                                                                <div key={item.geo_name} className="p-2 border flex-fill"><img width={20} height={20} src={item.geo_img}></img> {item.geo_name} - {item.quantity} </div>
                                                            )
                                                        }
                                                        else{
                                                            return null;
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        :<div className='text-center'><Loading></Loading></div>
                                    }
                                </div>
                            </div>
                        </div>

                        {/* <div className='card'>
                            <div className='card-body'>
                                {this.state.chart_show?
                                    <div className='row'>
                                        <div className='col-2' style={{textAlign:'left'}}>
                                            <Pie options={{plugins:{title: {display: false,},legend: {display: false}}}} style={{width:'100%', maxHeight:150}} data={this.state.chart_data} />
                                        </div>
                                        <div className='col-10'>
                                            
                                            <div className="d-flex flex-wrap ">
                                                {this.state.list_char_data.map((item:any) => {
                                                    if(this.state.list_char_data.indexOf(item) < 20){
                                                        return (
                                                            <div key={item.geo_name} className="p-2 border flex-fill"><img width={20} height={20} src={item.geo_img}></img> {item.geo_name} - {item.quantity} </div>
                                                        )
                                                    }
                                                    else{
                                                        return null;
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    :<div className='text-center'><Loading></Loading></div>
                                }
                            </div>
                        </div> */}
                        
                        {this.RenderTable()}
                    </div>
                    <CustomModal visible={this.state.iframe_modal} header={'Iframe'} size={'big'} callback={()=>{this.setState({iframe_modal:false})}}>
                        <div style={{position:'relative', width:'100%',  overflow:'hidden'}}>
                            <iframe style={{width:(window.innerWidth < 1020)?'100%':'120%', marginLeft:(window.innerWidth < 1020)?0:-220, marginTop:-60, minHeight:600, overflow:'hidden'}} src={this.state.iframe_url}></iframe>
                        </div>
                    </CustomModal>
                </div>
            </div>
        );
    }
}

