import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, urlNavigate, getUrlPath, spinner } from '../components/function';

import StatusSelectList from '../components/AppStatusSelect';
import Loading from './Loading';
import GeoListSelect from '../components/GeoListSelect';
import AppGroupsSelect from '../components/AppGroupsSelect';
import DevelopersSelect from '../components/DevelopersSelect';
import Select from 'react-select';
import CustomModal from '../components/CustomModal';
import AppsSelectList from '../components/AppsSelectList';


let url = getUrlPath();

export default class LeadsPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
       
        total_analyse:[
          {key:0, activ:true, name:'public', color:'rgba(25, 135, 84, 1)', icon:<i className="material-icons people_alt">&#xea21;</i>, data:<div className="spinner-border spinner-border-sm"></div>},
          {key:1, activ:false, name:'develop', color:'#0d6efd', icon:<i className="material-icons person_add_alt_1">&#xef65;</i>, data:spinner()},
          {key:2, activ:false, name:'moderation', color:'rgba(255, 193, 1)', icon:<i className="material-icons person_remove_alt_1">&#xef67;</i>, data:spinner()},
          {key:3, activ:false, name:'ban', color:'#e72e2e', icon:<i className="material-icons paid">&#xf041;</i>, data:spinner()},
          {key:4, activ:false, name:'all', color:'#2b2b2b', icon:<i className="material-icons people_alt">&#xea21;</i>, data:spinner()},
        ],
        filter_request:{group:null, developer:null},
        limit:[0, 20],
        table_data:[],
        table_data_load:false,
        btn_load_more:false,

        add_app_modal:false,
        add_app_data_name:'',
        add_app_data_package:'',
        add_app_data_store:(url[1] === 'android')?'GOOGLE_PLAY':'APP_STORE',
        add_app_data_developer:0,

        iframe_modal:false,
        iframe_url:'',
      }
    }
  
    componentDidMount(){
      this.GetList('start_load');
    }

   
  
    GetList = (type:string) => {
      let requestData:any = {select:['name', 'package', 'store', 'status', 'activ', 'production', 'count_installs', 'icon', 'testing'], where:[{platform:[url[1]]}, {status:[url[2]]}, {team:getUserData('team')}], limit:this.state.limit};
      if(type == 'start_load'){
        requestData.total_data = true;
        this.setState({
          total_analyse:[
            {key:0, activ:true, name:'public', color:'rgba(25, 135, 84, 1)', icon:<i className="material-icons people_alt">&#xea21;</i>, data:spinner()},
            {key:1, activ:false, name:'develop', color:'#0d6efd', icon:<i className="material-icons person_add_alt_1">&#xef65;</i>, data:spinner()},
            {key:2, activ:false, name:'moderation', color:'rgba(255, 193, 1)', icon:<i className="material-icons person_remove_alt_1">&#xef67;</i>, data:spinner()},
            {key:3, activ:false, name:'ban', color:'#e72e2e', icon:<i className="material-icons paid">&#xf041;</i>, data:spinner()},
            {key:4, activ:false, name:'all', color:'#2b2b2b', icon:<i className="material-icons people_alt">&#xea21;</i>, data:spinner()},
          ],
          limit:[0, 20],
          table_data:[],
          table_data_load:false,
        })
      }

      if(this.state.filter_request.group != null){
        requestData.where.push({group:this.state.filter_request.group});
      }
      if(this.state.filter_request.developer != null){
        requestData.where.push({developer:this.state.filter_request.developer});
      }

      // console.log(requestData)
      // console.log(this.state.filter_request)
      ApiReq('/apps/get_apps_data/', requestData).then(res => {
        if(res != false){
          if(res.success == true){
            if(type == 'start_load'){
              // console.log(res)
              if(res.total_data != null){
                let total_analyse = this.state.total_analyse;
                total_analyse[0].data = res.total_data.public;
                total_analyse[1].data = res.total_data.develop;
                total_analyse[2].data = res.total_data.moderation;
                total_analyse[3].data = res.total_data.ban;
                total_analyse[4].data = res.total_data.all;
                this.setState({
                  total_analyse:total_analyse
                })
              }
              this.setState({
                table_data:res.data,
                table_data_load:true,
                btn_load_more:true,
              })
            }
            else{
              if(res.data.length > 0){
                let table_data = this.state.table_data;
                for(let i=0; i<res.data.length; i++){
                  table_data.push(res.data[i]);
                }
                this.setState({
                  table_data:table_data,
                  table_data_load:true,
                  btn_load_more:true
                })
              }
              else{
                this.setState({
                  btn_load_more:null,
                  table_data_load:true,
                })
              }
            }
          }
          else{
            notySend('error', 'error #'+res.error+'\n')
          }
        }
      })
    }

    AddApp = () => {
      if(this.state.add_app_data_name != '' && this.state.add_app_data_package != '' && this.state.add_app_data_developer != 0){
        ApiReq('/apps/add/', {name:this.state.add_app_data_name, package:this.state.add_app_data_package, developer:this.state.add_app_data_developer, platform:url[1], store:this.state.add_app_data_store, team:getUserData('team')}).then(res => {
          if(res != false){
            if(res.success == true){
              urlNavigate('/app/'+this.state.add_app_data_package+'/');
            }
            else{
              notySend('error', 'error #'+res.error+'\n');
            }
          }
        })
      }
      else{
        notySend('error', 'params name, package, developer must be required!');
      }
    }

    RenderBtnLoadMore = () => {
      if(this.state.btn_load_more == true){
        return(
          <div className='text-center'><button onClick={()=>{this.setState({limit:[this.state.limit[0]+20, this.state.limit[1]], btn_load_more:false}); setTimeout(()=>{this.GetList('more_load')}, 200)}} style={{borderRadius:20}} className='btn btn-outline-primary'> {(this.state.btn_load_more == false) ? spinner(): null} load more</button></div>
        )
      }
      else{
        if(this.state.btn_load_more == null){
          return null;
        }
        else{
          return(
            <div className='text-center'><button disabled={true} onClick={()=>{this.setState({limit:[this.state.limit[1]+20, this.state.limit[1]],btn_load_more:false}); setTimeout(()=>{this.GetList('more_load')}, 200)}} style={{borderRadius:20}} className='btn btn-outline-primary'> {(this.state.btn_load_more == false) ? spinner(): null} load more</button></div>
          )
        }
      }
    }

    

    RenderTable = () => {
      if(this.state.table_data_load == true){
        return(
          <table className="table table-hover table-bordered text-center " style={{borderRadius:10}}>
            <thead >
              <tr>
                <th>Icon</th>
                <th>Name</th>
                <th>Installs</th>
                <th>Package</th>
                <th>Status</th>
                <th>Activ</th>
                <th>Production</th>
                <th>Testing</th>
                <th>Show</th>
              </tr>
            </thead>
            <tbody >
              {this.state.table_data.map((item:any) => {
                return(
                  this.RenderTableItem(item)
                )
              })}
                  
            </tbody>
          </table>
        )
      }
      else{
        return <div className='text-center'><Loading></Loading></div>
      }
    }

    RenderTableItem = (item:any) => {
      let statusColor:any;
      switch (item.status) {
          case 'public':
              statusColor='bg-success';
            break;
          case 'moderation':
              statusColor='bg-warning';
            break;
          case 'develop':
              statusColor='bg-info';
            break;
          case 'ban':
              statusColor='bg-danger';
            break;
      }
      let activColor:any;
      switch (item.activ) {
          case true:
              activColor='bg-success';
            break;
          case false:
              activColor='bg-danger';
            break;
      }
      let productionColor:any;
      switch (item.production) {
          case true:
              productionColor='bg-success';
            break;
          case false:
              productionColor='bg-danger';
            break;
      }
      let app_package:any = item.package;
      if(getUserData('team') == 'team2'){
          if(getUserData('roles').indexOf('admin') != -1){
              app_package = atob(item.package);
          }
      }
      let store_icon = require('../components/img/google_play_logo.png');
      switch (item.store) {
        case 'MI_STORE':
          store_icon = require('../components/img/mi_store_logo.png');
          break;
        case 'APP_STORE':
          store_icon = require('../components/img/app_store_logo.png');
          break;
      }
      return(
          <tr key={item.id}>
              <td className="app_line"><img src={item.icon} style={{borderRadius:10, minWidth:40, minHeight:40}} width="40" height="40"></img> <img style={{width:15, height:15, position:'absolute', marginTop:28, marginLeft:-10}} src={store_icon}></img></td>
              <td className="align-middle"><span>{item.name}</span></td>
              <td className="align-middle">{item.count_installs}</td>
              <td className="align-middle">{app_package}</td>
              <td className="align-middle"><span style={{borderRadius:'10rem'}} className={'badge '+statusColor}>{item.status}</span></td>
              <td className="align-middle"><span style={{borderRadius:'10rem'}} className={'badge '+activColor}>{item.activ.toString()}</span></td>
              <td className="align-middle"><span style={{borderRadius:'10rem'}} className={'badge '+productionColor}>{item.production.toString()}</span></td>
              <td className="align-middle">{(item.testing.length == 0)?<span style={{borderRadius:'10rem'}} className="badge bg-danger">not tested</span>:<span style={{borderRadius:'10rem'}} className={(item.testing[item.testing.length -1].status == 'reject')?'badge bg-warning':'badge bg-success'}>{item.testing[item.testing.length -1].status} ({item.testing.length})</span>}</td>
              <td><span onClick={()=>{this.setState({iframe_modal:true, iframe_url:'/app/'+item.package+'/'})}}><i style={{fontSize:26, marginTop:6}} className="material-icons remove_red_eye c_p text-primary">&#xe417;</i></span> <a href={'/app/'+item.package+'/'}><i className="material-icons arrow_circle_up text-primary" style={{rotate:'90deg',  fontSize:26, marginTop:6}}></i></a></td>
              {/* <td><a href={'/app/'+item.package+'/'} className="btn btn-info">Перейти</a></td> */}
          </tr>
      )
    }

    RenderDopAreaForSelect = () => {
      if(this.state.table_data.length == 0){
        return(
          <div style={{minHeight:150}}></div>
        )
      }
      else{
        return null;
      }
      
    }

  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
              <div className='row'>
                {this.state.total_analyse.map((item:any) => {
                  let cardStyle:any = {minWidth:126, borderColor:item.color};
                  if(url[2] == item.name){
                    cardStyle = {minWidth:126,  borderColor:item.color, boxShadow:'1px 1px 20px '+item.color};
                  }
                  return(
                    <div key={this.state.total_analyse.indexOf(item)} onClick={()=>{urlNavigate('/apps/'+url[1]+'/'+item.name+'/')}} className='col'>
                      <div className='card c_p' style={cardStyle}>
                        <div className='card-body text-center' style={{padding:8}}>
                          <div><strong style={{fontSize:16}}>{item.data} {item.name}</strong></div>
                          {/* <div>{item.name}</div> */}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='row'>
                <div className='col'>
                  <label>Search</label>
                  <AppsSelectList redir={true}></AppsSelectList>
                </div>
                <div className='col'>
                  <label>Group</label>
                  <AppGroupsSelect onChange={(t:any)=>{let fr = this.state.filter_request; fr.group = t.label; setTimeout(()=>{this.GetList('start_load')}, 500)}}></AppGroupsSelect>
                </div>
                <div className='col'>
                  <label>Developer</label>
                  <DevelopersSelect onChange={(t:any)=>{let fr = this.state.filter_request; fr.developer = t.value; setTimeout(()=>{this.GetList('start_load')}, 500)}}></DevelopersSelect>
                </div>
                <div className='col'>
                  <label>Add app</label>
                  <button onClick={()=>{this.setState({add_app_modal:true})}} className="btn btn-primary form-control"><i className="material-icons control_point c_p" style={{fontSize:16, margin:4}}></i>Add app</button>
                </div>
              </div>
              <hr></hr>
              <div className='table-responsive' style={{padding:2}}>
                  {this.RenderTable()}
                  <br></br>
              </div>
              {this.RenderBtnLoadMore()}
              <br></br>
              {this.RenderDopAreaForSelect()}
            </div>
          </div>
          <CustomModal visible={this.state.add_app_modal} header={'Add app ('+url[1]+')'} callback={()=>{this.setState({add_app_modal:false})}}>
            <div className='form-group'>
              <label>Name</label>
              <input value={this.state.add_app_data_name} onInput={(t)=>{this.setState({add_app_data_name:t.currentTarget.value})}} className='form-control'></input>
              <label>Package</label>
              <input value={this.state.add_app_data_package} onInput={(t)=>{this.setState({add_app_data_package:t.currentTarget.value})}} className='form-control'></input>
              {
                (url[1] === 'android')?
                <>
                  <label>Store</label>
                  <Select onChange={(t)=>{this.setState({add_app_data_store:t?.value})}} value={{label:this.state.add_app_data_store, value:this.state.add_app_data_store}} options={[{label:'GOOGLE_PLAY', value:'GOOGLE_PLAY'}, {label:'MI_STORE', value:'MI_STORE'}]}></Select>
                </>
                :
                null
              }
              <label>Developer</label>
              <DevelopersSelect value={this.state.add_app_data_developer} onChange={(t:any)=>{this.setState({add_app_data_developer:t.value})}}></DevelopersSelect>
              <hr></hr>
              <button onClick={()=>{this.AddApp()}} className="btn btn-primary form-control"><i className="material-icons control_point c_p" style={{fontSize:16, margin:4}}></i>Add app</button>
            </div>
          </CustomModal>
          <CustomModal visible={this.state.iframe_modal} header={'Iframe'} size={'big'} callback={()=>{this.setState({iframe_modal:false})}}>
              <div style={{position:'relative', width:'100%',  overflow:'hidden'}}>
                  <iframe style={{width:(window.innerWidth < 1020)?'100%':'120%', marginLeft:(window.innerWidth < 1020)?0:-220, marginTop:-60, minHeight:600, overflow:'hidden'}} src={this.state.iframe_url}></iframe>
              </div>
          </CustomModal>
        </div>
      );
    }
}

