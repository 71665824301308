import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, urlNavigate, getUrlPath } from '../components/function';
import Loading from '../pages/Loading';
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, ArcElement, BarElement } from 'chart.js';
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import * as moment from 'moment-timezone';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ArcElement
);

let color = '';
if(window.localStorage.getItem(window.location.origin+'_pixelcrm_theme') == null){
  color = 'white';
}
else{
  if(window.localStorage.getItem(window.location.origin+'_pixelcrm_theme') == 'dark'){
    color = 'white';
  }
  else{
    color = '#495057'
  }
}

ChartJS.defaults.color = color;

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
    style:{
      color:'white'
    }
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
let data:any = {
  labels,
  total_leads:0,
  accepted:0,
  rejected:0,
  deposits:0,
  invalid:0,
  no_answer:0,
  datasets: [
    {
      fill: true,
      label: 'Total leads',
      data: [],
      borderColor: 'rgb(0, 123, 255)',
      backgroundColor: 'rgba(0, 123, 255, 0)',
    },
    {
      fill: true,
      label: 'Accepted',
      data: [],
      borderColor: 'rgb(40, 167, 69)',
      backgroundColor: 'rgba(40, 167, 69, 0)',
    },
    {
      fill: true,
      label: 'Rejected',
      data: [],
      borderColor: 'rgb(220, 53, 70)',
      backgroundColor: 'rgba(220, 53, 70, 0)',
    },
    {
      fill: true,
      label: 'Deposits',
      data: [],
      borderColor: 'rgb(255, 193, 7)',
      backgroundColor: 'rgba(255, 193, 7, 0)',
    },
    {
      fill: true,
      label: 'Invalid',
      data: [],
      borderColor: 'rgb(209, 19, 187)',
      backgroundColor: 'rgba(209, 19, 187, 0)',
    },
    {
      fill: true,
      label: 'No answer',
      data: [],
      borderColor: 'rgb(252, 122, 0)',
      backgroundColor: 'rgba(252, 122, 0, 0)',
    },
  ],
};

let main_chart_data_last_day:any = {
  labels: ['Accepted', 'Rejected', 'Deposits', 'Invalid', 'No answer'],
  total_leads:0,
  ftd_leads:0,
  datasets: [
    {
      data: [],
      backgroundColor: [
        'rgba(40, 167, 69, 0.3)',
        'rgba(220, 53, 70, 0.3)',
        'rgba(255, 193, 7, 0.3)',
        'rgba(209, 19, 187, 0.3)',
        'rgba(252, 122, 0, 0.3)',
      ],
      borderColor: [
        'rgba(40, 167, 69, 1)',
        'rgba(220, 53, 70, 1)',
        'rgba(255, 193, 7, 1)',
        'rgba(209, 19, 187, 1)',
        'rgba(252, 122, 0, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

let main_chart_data_last_week:any = {
  labels: ['Accepted', 'Rejected', 'Deposits', 'Invalid', 'No answer'],
  total_leads:0,
  ftd_leads:0,
  datasets: [
    {
      data: [],
      backgroundColor: [
        'rgba(40, 167, 69, 0.3)',
        'rgba(220, 53, 70, 0.3)',
        'rgba(255, 193, 7, 0.3)',
        'rgba(209, 19, 187, 0.3)',
        'rgba(252, 122, 0, 0.3)',
      ],
      borderColor: [
        'rgba(40, 167, 69, 1)',
        'rgba(220, 53, 70, 1)',
        'rgba(255, 193, 7, 1)',
        'rgba(209, 19, 187, 1)',
        'rgba(252, 122, 0, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

let main_chart_data_last_month:any = {
  labels: ['Accepted', 'Rejected', 'Deposits', 'Invalid', 'No answer'],
  total_leads:0,
  ftd_leads:0,
  datasets: [
    {
      data: [],
      backgroundColor: [
        'rgba(40, 167, 69, 0.3)',
        'rgba(220, 53, 70, 0.3)',
        'rgba(255, 193, 7, 0.3)',
        'rgba(209, 19, 187, 0.3)',
        'rgba(252, 122, 0, 0.3)',
      ],
      borderColor: [
        'rgba(40, 167, 69, 1)',
        'rgba(220, 53, 70, 1)',
        'rgba(255, 193, 7, 1)',
        'rgba(209, 19, 187, 1)',
        'rgba(252, 122, 0, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

let data3:any = {
  labels: [],
  datasets: [
    {
      label: 'Leads',
      data: [],
      backgroundColor: 'rgba(0, 123, 255, 0.3)',
      borderColor:'rgba(0, 123, 255, 1)',
      borderWidth: 1,
    },
    // {
    //   label: 'Dataset 2',
    //   data: [0, 1, 99],
    //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
    // },
  ],
};






export default class MainPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        chart:false,
        main_chart_data:main_chart_data_last_day,
        main_chart_data_period_name:'last day'
      }
    }
  
    componentDidMount(){
      //this.GetPartnerAnalytics();
    }
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
              <div className='card-body'>
                <div className='table-responsive'>
                  <div style={{width:'100%', maxHeight:400}}>
                    <h3>Welcome {getUserData('loginName')}</h3>
                    <span>TimeZone : {moment.tz.guess()}</span>
                  </div>
                </div>
              </div>
            </div>
        </div>
      );
    }
}

