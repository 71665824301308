import React, { Component } from 'react';
import { IonContent } from '@ionic/react';
import '../components/css/bootstrap-extended.css';

import '../components/css/icons.css';
import '../components/css/google-icons.css';
import '../components/css/white/bootstrap.min.css';
import '../components/css/white/style.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Navigation from '../components/Navigation';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MainPage from '../pages/MainPage';
import Loading from '../pages/Loading';
import Page404 from './Page404';
import LeadsPage from './AppsPage';
import AppPage from './AppPage';
import Login from './LoginPage';
import ListGeoPage from './ListGeoPage';
import BlackListIpPage from './BlackListIpPage';
import BlackListAsnPage from './BlackListAsnPage';
import AppGroupsPage from './AppGroupsPage';
import TestRequestPage from './TestRequestPage';
import AnalyticsPublicPage from './AnalyticsPublicPage';
import AnalyticsConversionPage from './AnalyticsConversionPage';
import InstallLogsPage from './InstallLogsPage';
import InstallLogPage from './InstallLogPage';
import ServiceConnectPage from './ServiceConnectPage';
import IntegrationPage from './IntegrationPage';
import AnalyticsDeveloperPage from './AnalyticsDeveloperPage';
import AnalyticsDevicesPage from './AnalyticsDevicesPage';
import ApiKeysPage from './ApiKeysPage';
import TransferEventsPage from './TransferEventsPage';
import AppDomainsPage from './AppDomainsPage';
import SipcPage from './SipcPage';
import QuantityInstallAnalyticsPage from './QuantityInstallAnalyticsPage';
import UsersPage from './UsersPage';

import {  checkSmartRout, urlNavigate, getUserData, ifIframe } from '../components/function';




export default class Home extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
          page_full_load:true,
      }
    }
  
    componentDidMount(){
        this.StartRout();
    }


    StartRout = () => {
        let routs = [
            {
                rout:'/login/',
                title:'Login',
                content:<Login></Login>,
                roles:[]
            },
            {
                rout:'/home/',
                title:'Home',
                content:<><Header></Header><Navigation></Navigation><MainPage></MainPage><Footer></Footer></>,
                roles:[]
            },
            {
                rout:'/analytics_public/',
                title:'Analytics public',
                content:<><Header></Header><Navigation></Navigation><AnalyticsPublicPage></AnalyticsPublicPage><Footer></Footer></>,
                roles:['admin']
            },
            {
                rout:'/analytics_conversion/',
                title:'Analytics conversion',
                content:<><Header></Header><Navigation></Navigation><AnalyticsConversionPage></AnalyticsConversionPage><Footer></Footer></>,
                roles:['admin']
            },
            {
                rout:'/analytics_developer/',
                title:'Analytics developer',
                content:<><Header></Header><Navigation></Navigation><AnalyticsDeveloperPage></AnalyticsDeveloperPage><Footer></Footer></>,
                roles:[]
            },
            {
                rout:'/analytics_devices/',
                title:'Analytics devices',
                content:<><Header></Header><Navigation></Navigation><AnalyticsDevicesPage></AnalyticsDevicesPage><Footer></Footer></>,
                roles:['admin']
            },
            {
                rout:'/apps/([android,ios])/([public,develop,moderation,ban,all])/',
                title:'Apps',
                content:<><Header></Header><Navigation></Navigation><LeadsPage></LeadsPage><Footer></Footer></>,
                roles:[]
            },
            {
                rout:'/app/(*)/',
                title:'App',
                content:<><Header></Header><Navigation></Navigation><AppPage></AppPage><Footer></Footer></>,
                roles:[]
            },
            {
                rout:'/list_geo/',
                title:'List geo',
                content:<><Header></Header><Navigation></Navigation><ListGeoPage></ListGeoPage><Footer></Footer></>,
                roles:['admin']
            },
            {
                rout:'/black_list_ip/',
                title:'Black list ip',
                content:<><Header></Header><Navigation></Navigation><BlackListIpPage></BlackListIpPage><Footer></Footer></>,
                roles:['admin']
            },
            {
                rout:'/black_list_asn/',
                title:'Black list asn',
                content:<><Header></Header><Navigation></Navigation><BlackListAsnPage></BlackListAsnPage><Footer></Footer></>,
                roles:['admin']
            },
            {
                rout:'/app_groups/',
                title:'App groups',
                content:<><Header></Header><Navigation></Navigation><AppGroupsPage></AppGroupsPage><Footer></Footer></>,
                roles:['admin']
            },
            {
                rout:'/test_request/(*)/',
                title:'Test Request',
                content:<><Header></Header><Navigation></Navigation><TestRequestPage></TestRequestPage><Footer></Footer></>,
                roles:[]
            },
            {
                rout:'/install_logs/(*)/',
                title:'Install logs',
                content:<><Header></Header><Navigation></Navigation><InstallLogsPage></InstallLogsPage><Footer></Footer></>,
                roles:[]
            },
            {
                rout:'/install_log/(*)/',
                title:'Install log',
                content:<><Header></Header><Navigation></Navigation><InstallLogPage></InstallLogPage><Footer></Footer></>,
                roles:[]
            },
            {
                rout:'/service_connect/',
                title:'Service connect',
                content:<><Header></Header><Navigation></Navigation><ServiceConnectPage></ServiceConnectPage><Footer></Footer></>,
                roles:['admin']
            },
            {
                rout:'/api_keys/',
                title:'Api keys',
                content:<><Header></Header><Navigation></Navigation><ApiKeysPage></ApiKeysPage><Footer></Footer></>,
                roles:['admin']
            },
            {
                rout:'/transfer_events/',
                title:'Transfer events',
                content:<><Header></Header><Navigation></Navigation><TransferEventsPage></TransferEventsPage><Footer></Footer></>,
                roles:['admin']
            },
            {
                rout:'/integration/',
                title:'Integration',
                content:<><Header></Header><Navigation></Navigation><IntegrationPage></IntegrationPage><Footer></Footer></>,
                roles:[]
            },
            {
                rout:'/sipc/',
                title:'Sipc',
                content:<><Header></Header><Navigation></Navigation><SipcPage></SipcPage><Footer></Footer></>,
                roles:[]
            },
            {
                rout:'/quantity_install_analytics/',
                title:'Quantity Install Analytics',
                content:<><Header></Header><Navigation></Navigation><QuantityInstallAnalyticsPage></QuantityInstallAnalyticsPage><Footer></Footer></>,
                roles:['admin']
            },
            {
                rout:'/users/',
                title:'Users',
                content:<><Header></Header><Navigation></Navigation><UsersPage></UsersPage><Footer></Footer></>,
                roles:['admin']
            },
            // {
            //     rout:'/app_domains/',
            //     title:'App domains',
            //     content:<><Header></Header><Navigation></Navigation><AppDomainsPage></AppDomainsPage><Footer></Footer></>,
            //     roles:['admin']
            // },
        ];
        
        let c_url = window.location.pathname;
        if(c_url != '/'){
            let c_rout:any = null;
            for(let i=0; i<routs.length; i++){
                if(routs[i].rout.indexOf('(') != -1){
                    if(checkSmartRout(c_url, routs[i].rout)){
                        c_rout = routs[i];
                        break;
                    }
                }
                else{
                    if(c_url == routs[i].rout){
                        c_rout = routs[i];
                        break;
                    }
                }
            }
            //console.log(getUserData('login'), c_rout.title)
            if(getUserData('login') == true){
                if(c_rout == null){
                    return <Page404></Page404>;
                }
                else{
                    localStorage.setItem('page_name', c_rout.title);
                    return c_rout.content;
                }
            }
            else{
                if(c_rout.title !== 'Login'){
                    //return <Page404></Page404>;
                    urlNavigate('/login/');
                }
                else{
                    localStorage.setItem('page_name', c_rout.title);
                    return c_rout.content;
                }
            }
        }
        else{
            urlNavigate('/login/');
        }
        
    }    
  
    render() {
        if(this.state.page_full_load == true){
            return (
                <div style={{overflowY:'scroll'}}>
                    <div id='mainwrapper' className="wrapper" >
                        {this.StartRout()}
                        <ToastContainer newestOnTop={true} style={{marginTop:ifIframe()?50:0}}/>
                        {this.state.pay_modal}
                        {this.state.setting_modal}
                    </div>
                </div>
              );
        }
        else{
            return (
                <div style={{width:'100%', height:'100%'}}>
                    <Loading center={true}></Loading>
                </div>
            )
        }
    }
}

