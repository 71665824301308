import React, { Component } from 'react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, encryptAES, getUrlPath, spinner } from '../components/function';
import Loading from '../pages/Loading';
import DevelopersSelect from '../components/DevelopersSelect';
import Select from 'react-select';
import { UAParser } from 'ua-parser-js';

export default class AnalyticsDevicesPage extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            page_load:true,
            list_table:[],
            req_status:'expectation',
            req_total_items:0,
            req_loaded_items:0,
            req_time_left:0,
            filter_from:'',
            filter_to:'',
            filter_geo:[],
            filter_limit1:0,
            filter_limit2:1000,
        }
    }
  
    componentDidMount(){
        
    }



    GetItemsData = () => {
        ApiReq('/analytics_log/get_ua/', {from:this.state.filter_from, to:this.state.filter_to, limit:[this.state.filter_limit1, this.state.filter_limit2]}).then(res => {
            if(res != false){
                if(res.success == true){
                    this.setState({
                        req_loaded_items:this.state.req_loaded_items + res.data.length,
                        req_time_left:this.state.req_time_left - 3,
                        filter_limit1:this.state.filter_limit1 + 1000
                    })
                    let parser:any = null;
                    let parserResults:any = null;
                    let list_table = this.state.list_table;
                    for(let i=0; i<res.data.length; i++){
                        let temp = false;
                        parser = new UAParser(res.data[i]['user_agent']);
                        parserResults = parser.getResult();
                        // console.log(res.data[i])
                        // console.log(new UAParser(res.data[i]['user_agent']).getResult().device.vendor)
                        for(let j=0; j<list_table.length; j++){
                            if(list_table[j].brand === parserResults.device.vendor){
                                temp = true;
                                list_table[j].count ++;
                                if(list_table[j].models.indexOf(parserResults.device.model) === -1){
                                    list_table[j].models.push(parserResults.device.model);
                                }
                                break;
                            }
                        }
                        if(temp === false){
                            // console.log(parserResults.device.vendor)
                            list_table.push({brand:parserResults.device.vendor, count:1, models:[parserResults.device.model]})
                        }
                    }
        
                    this.setState({
                        list_table:list_table
                    })
                }
                else{
                    notySend('error', 'error #'+res.error+'\n');
                }
            }
        })
        
      }
    
      
      GetTotalItemsData = () => {
        if(this.state.filter_from != '' && this.state.filter_to != ''){
            this.setState({
                req_status:<div className="spinner-border spinner-border-sm text-info"></div>,
                req_total_items:0,
                req_loaded_items:0,
                req_time_left:0,
                filter_limit1:0,
                filter_limit2:1000,
                list_table:[],
            })
    
            ApiReq('/analytics_log/get_ua_total/', {from:this.state.filter_from, to:this.state.filter_to}).then(res => {
                if(res != false){
                    if(res.success == true){
                        this.setState({
                            req_total_items:res.total,
                            req_time_left:Math.ceil(res.total/1000)*3
                        })
                        notySend('success', 'success! wait until all items are downloaded, the next notification will inform you when they are ready');
                        let req_interval = setInterval(() => {
                            if(this.state.req_loaded_items < this.state.req_total_items){
                                this.GetItemsData();
                            }
                            else{
                                clearInterval(req_interval);
                                this.setState({
                                    req_status:'expectation'
                                })
                                this.SortTableData();
                            }
                        },2000);
                        
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n');
                    }
                }
            })
           
        }
        else{
            this.setState({
                req_status:'expectation'
            })
            notySend('error', 'Params from and to must not be empty!');
        }
      }
    
      SortTableData = () => {
        let list_table = this.state.list_table;
        let sort_list_table:any = [];
        let counts_arr:any = [];
        for(let i=0; i<list_table.length; i++){
            counts_arr.push(list_table[i].count);
        }
        counts_arr.sort(function (a:any, b:any) {  return b - a;  })
        
        for(let i=0; i<counts_arr.length; i++){
            for(let j=0; j<list_table.length; j++){
                if(counts_arr[i] === list_table[j].count){
                    sort_list_table.push(list_table[j])
                    list_table.splice(j, 1)
                }
            }
        }
    
        this.setState({
            list_table:sort_list_table
        })
      }
  
    render() {
        return (
            <div className='page-content'>
                <div className="card">
                    <div className="card-body">
                        <div> 
                            <div>
                                <div className="row">
                                    <div className="col text-center">
                                        <label>From</label>
                                        <input onChange={(t)=>{this.setState({filter_from:t.currentTarget.value})}} className='form-control' type='date'></input>
                                    </div>
                                    <div className="col text-center">
                                        <label>To</label>
                                        <input onChange={(t)=>{this.setState({filter_to:t.currentTarget.value})}} className='form-control' type='date'></input>
                                    </div>
                                    {/* <div className="col text-center">
                                        <label>Geo</label>
                                        <GeoSelectList onClick={(item)=>{alert(item)}}></GeoSelectList>
                                    </div> */}
                                    <div className="col text-center">
                                        <label>Get report</label>
                                        <button onClick={()=>{this.GetTotalItemsData()}} disabled={(this.state.req_status == 'expectation')?false:true} className="btn btn-primary form-control">Get report</button>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div><strong>status: </strong> {this.state.req_status}, <strong>total items: </strong>{this.state.req_total_items}, <strong>loaded items: </strong>{this.state.req_loaded_items}, <strong>time left: </strong>{this.state.req_time_left}s</div>
                            <hr></hr>
                            <div style={{minHeight:500}} className="table-responsive p-1">
                                <table className='table table-bordered table-hover table-striped'>
                                    <thead>
                                        <tr>
                                            <th>brand</th>
                                            <th>count installs</th>
                                            <th>% of installs</th>
                                            <th>models</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.list_table.map((item:any) => {
                                            return(
                                                <tr key={this.state.list_table.indexOf(item)}>
                                                    <td>{item.brand}</td>
                                                    <td>{item.count}</td>
                                                    <td>{((item.count/this.state.req_total_items)*100).toFixed(2) }%</td>
                                                    <td>{JSON.stringify(item.models)}</td>
                                                </tr>
                                            )
                                        })}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

