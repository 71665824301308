import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate } from './function';
import Select from 'react-select'

  
  

export default class GeoSelectList extends Component<any, any> {
  constructor(props: any){
    super(props);
    this.state = {
        list_geo:[],
        item_val:null
    }
  }

  componentDidMount(){
    this.GetAppsList();
    
  }

  GetAppsList = () => {
    ApiReq('/geo/get_list_geo/').then(res => {
      if(res != false){
          if(res.success == true){
              let list_geo:any = [];
              for(let i=0; i<res.data.length; i++){
                list_geo.push({ value: res.data[i].code, label: <span><img width="25" height="25" src={res.data[i].img} style={{borderRadius:12}}></img> {res.data[i].name+' ('+res.data[i].code+')'}</span>, })
              }

              this.setState({
                  list_geo:list_geo
              })
          }
          else{
                notySend('error', 'error #'+res.error+'\n'+res.info);
          }
        }
    })
  }

  styles = {
    option: (provided:any, state:any) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      textAlign:'left'
    }),
  };

  Change = (res:any) => {
    if(this.props.onChange != null){
        this.props.onChange(res);
    }
    this.setState({
      item_val:res
    }) 
  }

  render() {
    if(this.props.isMulti){
      return (
        <Select value={this.state.item_val} isMulti={true} onChange={(item:any)=>{this.Change(item)}}  options={this.state.list_geo}  styles={this.styles}/>
      );
    }
    else{
      return (
        <Select value={this.state.item_val} onChange={(item:any)=>{this.Change(item)}}  options={this.state.list_geo}  styles={this.styles}/>
      );
    }
  }
}


