import React, { Component } from 'react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, encryptAES, getUrlPath, spinner, convertUTCTimeToLocalTime } from '../components/function';
import Loading from '../pages/Loading';
import DevelopersSelect from '../components/DevelopersSelect';
import Select from 'react-select';

export default class AnalyticsDeveloperPage extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            page_load:true,
            developer:null,
            from_date:'',
            to_date:'',
            type:'create',
            list_table:[],
            dashboard:{apps:0, public:0, where_public:0, ban:0, total_installs:0, average_installs:0, lifetime:0, dev_score:0},
        }
    }
  
    componentDidMount(){
        
    }


    

    GetData = () => {
        if(this.state.developer != null){
            this.setState({page_load:false})
            ApiReq('/analytics_developer/', {developer:this.state.developer, from_date:this.state.from_date, to_date:this.state.to_date, type:this.state.type}).then(res => {
                if(res != false){
                    if(res.success == true){
                        this.setState({
                            list_table:res.data,
                            page_load:true
                        })
                        setTimeout(()=>{
                            this.GenerateDashboard();
                        },800)
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n');
                    }
                }
            })
        }
        else{
            notySend('error', 'select developer');
        }
    }

    GenerateDashboard = () => {
        let dashboard = {apps:this.state.list_table.length, public:0, where_public:0, ban:0, total_installs:0, average_installs:0, lifetime:0, dev_score:0};
        let lifetime = 0;
        let lifetime_count_apps = 0;
        for(let i=0; i<this.state.list_table.length; i++){
          dashboard.total_installs = dashboard.total_installs + Number(this.state.list_table[i].count_installs)
          if(this.state.list_table[i].status == 'public'){
            dashboard.public = dashboard.public + 1
          }
          if(this.state.list_table[i].status == 'ban'){
            dashboard.ban = dashboard.ban + 1
          }
          if(this.state.list_table[i].public_time != '0000-00-00 00:00:00'){
            dashboard.where_public = dashboard.where_public + 1;
          }
          if(typeof this.GetLifetime(this.state.list_table[i].ban_time, this.state.list_table[i].public_time, this.state.list_table[i].status) == 'number'){
            lifetime = lifetime + Number(this.GetLifetime(this.state.list_table[i].ban_time, this.state.list_table[i].public_time, this.state.list_table[i].status))
            lifetime_count_apps++;
          }
          
        }
    
        dashboard.average_installs = Math.round(Number(dashboard.total_installs)/this.state.list_table.length);
        dashboard.lifetime = Math.round(lifetime/lifetime_count_apps)
        
        this.setState({
          dashboard:dashboard
        })
    
        
      }
    
      GetLifetime = (dt1:any, dt2:any, status:string) => {
        if(status == 'public' || status == 'ban'){
          if(status == 'public'){
            if(dt2 != '' && dt2 != '0000-00-00 00:00:00'){
              let dif = Date.now()/1000 - Math.floor(new Date(dt2).getTime() / 1000);
              
              var daysDifference = Math.floor(dif/86400);
              // dif -= daysDifference*1000*60*60*24;
              return daysDifference;
            }
            else{
              return '-';
            }
          }
          else{
            if(dt2 != '' && dt2 != '0000-00-00 00:00:00' && dt1 != '' && dt1 != '0000-00-00 00:00:00'){
              let dif = Math.floor(new Date(dt1).getTime() / 1000) - Math.floor(new Date(dt2).getTime() / 1000);
              
              var daysDifference = Math.floor(dif/86400);
              // dif -= daysDifference*1000*60*60*24;
              return daysDifference;
            }
            else{
              return '-';
            }
          }
        }
        else{
          return '-';
        }
      }

    RenderTableItem = (item:any) => {
        return(
          <tr key={item.id}>
            <td>{this.state.list_table.indexOf(item)+1}</td>
            <td><a href={'/admin/app/'+item.package+'/'}>{item.name}</a></td>
            <td>{item.count_installs}</td>
            <td>{item.status}</td>
            <td>{convertUTCTimeToLocalTime(item.create_time)}</td>
            <td>{convertUTCTimeToLocalTime(item.public_time)}</td>
            <td>{convertUTCTimeToLocalTime(item.ban_time)}</td>
            <td>{this.GetLifetime(item.ban_time, item.public_time, item.status) + ' days'}</td>
          </tr>
        )
      }
  
    render() {
        return (
            <div className='page-content'>
                <div className="card" style={{minHeight:500}}>
                    <div className='card-body'>
                        <h6 className='text-center'>Внимание! Аналитика по разработчику считается наиболее актуальной за период с 01.07.2023 по сегоднящний день! (причиной является переодическая очистка базы данных от старых приложений для оптимизации системы. последняя очистка - 09.10.2023, были удалены приложения которые находились в бане до 01.07.2023, включительно)</h6>
                        <hr></hr>
                        <div className='row'>
                            <div className='col'>
                                <label>Developer</label>
                                <DevelopersSelect onChange={(t:any)=>{this.setState({developer:t.value})}}></DevelopersSelect>
                            </div>
                            <div className='col'>
                                <label>From</label>
                                <input onInput={(t)=>{this.setState({from_date:t.currentTarget.value.replace('T', ' ')})}} className='form-control' type='datetime-local'></input>
                            </div>
                            <div className='col'>
                                <label>To</label>
                                <input onInput={(t)=>{this.setState({to_date:t.currentTarget.value.replace('T', ' ')})}} className='form-control' type='datetime-local'></input>
                            </div>
                            <div className='col'>
                                <label>Type</label>
                                <Select onChange={(t:any)=>{this.setState({type:t.value})}} options={[{label:'create', value:'create'}, {label:'public', value:'public'}]}></Select>
                            </div>
                            <div className='col'>
                                <label>Get report</label>
                                <button onClick={()=>{this.GetData()}} disabled={!this.state.page_load} className='btn btn-primary form-control'>{this.state.page_load?null:spinner()} Get report</button>
                            </div>
                        </div>
                        <hr></hr>
                        <div className='row'>
                            <div className='col'>
                                <div className='card text-white' style={{minWidth:150}}>
                                    <div className='bg-info text-center' style={{padding:5, borderRadius:10}}>
                                    <label>apps</label>
                                    <h2>{this.state.dashboard.apps}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='card text-white' style={{minWidth:150}}>
                                    <div className='bg-success text-center' style={{padding:5, borderRadius:10}}>
                                    <label>were published</label>
                                    <h2>{this.state.dashboard.where_public}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='card text-white' style={{minWidth:150}}>
                                    <div className='bg-success text-center' style={{padding:5, borderRadius:10}}>
                                    <label>public now</label>
                                    <h2>{this.state.dashboard.public}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='card text-white' style={{minWidth:150}}>
                                    <div className='bg-danger text-center' style={{padding:5, borderRadius:10}}>
                                    <label>ban</label>
                                    <h2>{this.state.dashboard.ban}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='card text-white' style={{minWidth:150}}>
                                    <div className='bg-primary text-center' style={{padding:5, borderRadius:10}}>
                                    <label>total installs</label>
                                    <h2>{this.state.dashboard.total_installs}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='card text-white' style={{minWidth:150}}>
                                    <div className='bg-primary text-center' style={{padding:5, borderRadius:10}}>
                                    <label>average installs</label>
                                    <h2>{this.state.dashboard.average_installs}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='card text-white' style={{minWidth:150}}>
                                    <div className=' text-center' style={{padding:5, backgroundColor:'#2755a1', borderRadius:10}}>
                                    <label>average lifetime</label>
                                    <h2>{this.state.dashboard.lifetime} days</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="table-responsive text-center p-1">
                            <table className="table table-bordered text-center table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>app</th>
                                    <th>installs</th>
                                    <th>status</th>
                                    <th>create</th>
                                    <th>public</th>
                                    <th>ban</th>
                                    <th>lifetime</th>
                                </tr>
                            </thead>
                                <tbody>
                                    {this.state.list_table.map((item:any) => this.RenderTableItem(item))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

