import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, urlNavigate, getUrlPath } from '../components/function';
import Loading from '../pages/Loading';
import CustomModal from '../components/CustomModal';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
ChartJS.defaults.color = 'black';
const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
            color: 'black',
        },
      },
      title: {
        display: false,
      },
    },
  };

  let labels:any = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  let chart_data:any = {
    labels,
    datasets: [
      {
        label: 'Create',
        data: [],
        backgroundColor: 'rgba(41, 141, 255, 0.5)',
      },  
      {
        label: 'Moderation',
        data: [],
        backgroundColor: 'rgba(204, 191, 2, 0.5)',
      },
      {
        label: 'Public',
        data: [],
        backgroundColor: 'rgba(16, 163, 0, 0.5)',
      },
      {
        label: 'Ban',
        data: [],
        backgroundColor: 'rgba(245, 66, 75, 0.5)',
      },
    ],
};


export default class AnalyticsPublicPage extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            page_load:false,
        }
    }
  
    componentDidMount(){
        this.GetData();
    }

    GetData = () => {
        ApiReq('/analytics_public/get/', {}).then(res => {
            if(res != false){
                if(res.success == true){
                    for(let i=0; i<res.data.length; i++){
                        chart_data.datasets[0].data.push(res.data[i].data.create)
                        chart_data.datasets[1].data.push(res.data[i].data.moderation)
                        chart_data.datasets[2].data.push(res.data[i].data.public)
                        chart_data.datasets[3].data.push(res.data[i].data.ban)
                    }
                    this.setState({
                        page_load:true
                    })
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                }
            }
        })
    }

    
    render() {
        if(!this.state.page_load){
            return(
                <div className='page-content'>
                    <div className="card">
                        <div className='card-body'>
                            <div className='text-center'><Loading></Loading></div>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return (
                <div className='page-content'>
                    <div className="card">
                        <div className='card-body'>
                            <Bar style={{maxHeight:400, color:'black'}} options={options} data={chart_data} />
                            <hr></hr>
                            <div className='row'>
                                {labels.map((item:any) => {
                                    return(
                                        <div key={labels.indexOf(item)} className='col text-center'>
                                            <strong>{item}</strong>
                                            <br></br>
                                            <label>% from public</label>
                                            <br></br>
                                            <strong>{((chart_data.datasets[2].data[labels.indexOf(item)]/chart_data.datasets[0].data[labels.indexOf(item)])*100).toFixed(2)} %</strong>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

