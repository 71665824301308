import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, spinner, getUrlPath } from '../components/function';
import Loading from '../pages/Loading';
import CustomModal from '../components/CustomModal';

let url = getUrlPath();

export default class TestRequestPage extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            list_table:[],
            page_load:false,
            btn_load:true,
            app:url[1],
            ip:'',
            host:'',
            current_body:JSON.stringify({"app":url[1],"hash":"1608823819227-6410566072403469242","data":{"is_first_launch":true,"install_time":"2021-02-02 18:24:13.206","af_message":"organic install","af_status":"Organic"}}, undefined, 4),
            organic_body:{"app":url[1],"hash":"1608823819227-6410566072403469242","data":{"is_first_launch":true,"install_time":"2021-02-02 18:24:13.206","af_message":"organic install","af_status":"Organic"}},
            non_organic_body:{"app":url[1],"hash":"1608823819227-6410566072403469242","data":{"adgroup_id":"23849030067510613","retargeting_conversion_type":"none","is_fb":true,"is_first_launch":true,"iscache":false,"click_time":"2021-09-17 10:41:08.000","match_type":"srn","adset":"cz1","af_channel":"Facebook","is_paid":true,"campaign_id":"23849030066530613","install_time":"2021-09-17 10:41:47.043","media_source":"Facebook Ads","agency":null,"af_siteid":null,"af_status":"Non-organic","af_sub1":null,"ad_id":"23849030077850613","af_sub5":null,"af_sub4":null,"af_sub3":null,"af_sub2":null,"adset_id":"23849030066840613","campaign":"asd1_asd2_asd3_asd4","http_referrer":null,"is_mobile_data_terms_signed":true,"adgroup":"c1"}},
            response:'',
            request_status:null,
            request_time:null,
        }
    }
  
    componentDidMount(){
        this.GetData();
        this.GetIp();
    }

    GetData = () => {
        ApiReq('/apps/get_apps_data/', {select:['host'], where:[{package:[url[1]]}]}).then(res => {
            if(res != false){
              if(res.success == true){
                this.setState({
                    host:res.data[0].host,
                    page_load:true,
                })
              }
              else{
                notySend('error', 'error #'+res.error+'\n')
              }
            }
        })
    }

    GetIp = () => {
        fetch('https://geolocation-db.com/json/')
        .then(response => response.json())
        .then(data => {
            this.setState({
                ip:data.IPv4,
                btn_load:false,
            })
        });
    }

    RunRequest = () => {
        if(this.state.ip != '' && this.state.host != ''){
            this.setState({btn_load:true});
            ApiReq('/test_request/run/', {ip:this.state.ip, host:this.state.host+'?ip='+this.state.ip, body:JSON.parse(this.state.current_body)}).then(res => {
                if(res != false){
                  if(res.success == true){
                    this.setState({
                        response:res.data.response,
                        request_status:res.data.request_status,
                        request_time:res.data.request_time,
                        btn_load:false,
                    })
                  }
                  else{
                    notySend('error', 'error #'+res.error+'\n');
                  }
                }
            })
        }
        else{
            notySend('error', 'params ip and host must not be empty!');
        }
    }
    
  
    render() {
        if(!this.state.page_load){
            return(
                <div className='page-content'>
                    <div className="card">
                        <div className='card-body'>
                            <div className='text-center'><Loading></Loading></div>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return (
                <div className='page-content'>
                    <div className="card">
                        <div className='card-body'>
                            <div className='form-group'>
                                <label>App</label>
                                <input disabled={true} value={this.state.app} className='form-control'></input>
                                <label>Ip</label>
                                <input value={this.state.ip} onInput={(t)=>{this.setState({ip:t.currentTarget.value})}} className='form-control'></input>
                                <label>Host</label>
                                <input value={this.state.host} onInput={(t)=>{this.setState({host:t.currentTarget.value})}} className='form-control'></input>
                            </div>
                            <hr></hr>
                            <button onClick={()=>{this.setState({current_body:JSON.stringify(this.state.organic_body, undefined, 4)})}} className="btn btn-outline-secondary m-1">Organic</button>
                            <button onClick={()=>{this.setState({current_body:JSON.stringify(this.state.non_organic_body, undefined, 4)})}} className="btn btn-outline-secondary m-1">Non-Organic</button>
                            <button disabled={this.state.btn_load} onClick={()=>{this.RunRequest()}} className="btn btn-outline-primary m-1">{this.state.btn_load?spinner():null} Run request</button>
                            <br></br>
                            <br></br>
                            <div className='row'>
                                <div className='col'>
                                    <p className='text-center'><strong>Body</strong></p>
                                    <textarea onInput={(t)=>{this.setState({current_body:t.currentTarget.value})}} value={this.state.current_body} style={{minHeight:400}} className='form-control m-1'></textarea>
                                </div>
                                <div className='col'>
                                    <p className='text-center'><strong>Response:</strong> <strong>Status:{this.state.request_status}</strong> <strong>Time:{this.state.request_time}</strong></p>
                                    <textarea disabled={true} value={this.state.response} style={{minHeight:400}} className='form-control m-1'></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

