import React, { Component } from 'react';
import { ApiReq, notySend, getRandomString, getUserData, urlNavigate, getUrlPath } from '../components/function';
import Loading from '../pages/Loading';
import CustomModal from '../components/CustomModal';
import Select from 'react-select';

export default class UsersPage extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            list_table:[],
            page_load:false,
            modal:false,
            modalType:'add',
            modalUserData:{id:0,login_name:'',first_name:'',last_name:'',patronymic:'',roles:'developer',telegram:'', password:getRandomString(20)},
            cacheModalUserData:{},
            modalUserShowNewPassword:false,
        }
    }
  
    componentDidMount(){
        this.GetData();
    }

    GetData = () => {
        ApiReq('/users/get_users/').then(res => {
            if(res != false){
                if(res.success == true){
                    this.setState({
                        list_table:res.data,
                        page_load:true
                    })
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                }
            }
        })
    }

    Add = () => {
        ApiReq('/users/add_user/', this.state.modalUserData).then(res => {
            if(res != false){
                if(res.success == true){
                    notySend('success', 'success');
                    this.GetData();
                }
                else{
                    notySend('error', 'error #'+res.error+'\n');
                }
            }
        })
    }

    Update = () => {
        let updateData:any = {};
        for(let key in this.state.modalUserData){
            if(this.state.cacheModalUserData[key] === undefined){
                updateData[key] = this.state.modalUserData[key];
            }
            else{
                if(this.state.cacheModalUserData[key] != this.state.modalUserData[key]){
                    updateData[key] = this.state.modalUserData[key];
                }
            }
        }
        if(Object.keys(updateData).length !== 0){
            console.log(updateData)
            updateData['id'] = this.state.modalUserData['id'];
            ApiReq('/users/update_user/', updateData).then(res => {
                if(res != false){
                    if(res.success == true){
                        notySend('success', 'success');
                        this.GetData();
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n');
                    }
                }
            })
        }
        else{
            notySend('error', 'No data changes to update');
        }
    }

    Delete = () => {
        ApiReq('/users/delete_user/', {id:this.state.modalUserData.id}).then(res => {
            if(res != false){
                if(res.success == true){
                    notySend('success', 'success');
                    this.GetData();
                }
                else{
                    notySend('error', 'error #'+res.error+'\n');
                }
            }
        })
    }
  
    render() {
        if(!this.state.page_load){
            return(
                <div className='page-content'>
                    <div className="card">
                        <div className='card-body'>
                            <div className='text-center'><Loading></Loading></div>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return (
                <div className='page-content'>
                    <div className="card">
                        <div className='card-body'>
                            <div style={{textAlign:'right'}}>
                                <button onClick={()=>{this.setState({modal:true, modalType:'add', modalUserData:{id:0,login_name:'',first_name:'',last_name:'',patronymic:'',roles:'developer',telegram:'',password:getRandomString(20)}})}} className="btn btn-primary"><i className="material-icons control_point c_p" style={{fontSize:16, margin:4}}></i>Add user</button>
                            </div>
                            <hr></hr>
                            <div className='table-responsive' style={{padding:2}}>
                                <table className='table table-hover table-bordered text-center'>
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Login</th>
                                            <th>First name</th>
                                            <th>Last name</th>
                                            <th>Show</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.list_table.map((item:any) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.login_name}</td>
                                                    <td>{item.first_name}</td>
                                                    <td>{item.last_name}</td>
                                                    <td><button onClick={()=>{this.setState({modal:true, modalType:'show', modalUserData:item, cacheModalUserData:item})}} className='btn btn-primary'>Show</button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <CustomModal visible={this.state.modal} header={(this.state.modalType == 'add')?'Add':'Show'} callback={()=>{this.setState({modal:false})}}>
                        <div className='form-group'>
                            {(this.state.modalType == 'show')?
                                <div style={{float:'right'}}>
                                    <label className='form-check-label'>
                                        <input
                                            type="checkbox"
                                            checked={this.state.modalUserShowNewPassword}
                                            onChange={()=>{this.setState({modalUserShowNewPassword:!this.state.modalUserShowNewPassword, modalUserData:{...this.state.modalUserData, password:getRandomString(20)}})}}
                                        />
                                        Update password
                                    </label>
                                </div>
                                :
                                null
                            }
                            
                            <label>Login</label>
                            <input value={this.state.modalUserData.login_name} placeholder='email@email.com' onInput={(t)=>{this.setState({modalUserData:{...this.state.modalUserData, login_name:t.currentTarget.value}})}} className='form-control'></input>
                            {(this.state.modalType == 'add' || (this.state.modalType == 'show' && this.state.modalUserShowNewPassword == true))?
                                <>
                                    <label>Password</label>
                                    <input value={this.state.modalUserData.password} onInput={(t)=>{this.setState({modalUserData:{...this.state.modalUserData, password:t.currentTarget.value}})}} className='form-control'></input>
                                </>
                                :
                                null
                            }
                            <label>First name</label>
                            <input value={this.state.modalUserData.first_name} onInput={(t)=>{this.setState({modalUserData:{...this.state.modalUserData, first_name:t.currentTarget.value}})}} placeholder='Ivan' className='form-control'></input>
                            <label>Last name</label>
                            <input value={this.state.modalUserData.last_name} onInput={(t)=>{this.setState({modalUserData:{...this.state.modalUserData, last_name:t.currentTarget.value}})}} placeholder='Ivanov' className='form-control'></input>
                            <label>Patronymic</label>
                            <input value={this.state.modalUserData.patronymic} onInput={(t)=>{this.setState({modalUserData:{...this.state.modalUserData, patronymic:t.currentTarget.value}})}} placeholder='Ivanovich' className='form-control'></input>
                            <label>Role</label>
                            <Select value={{label:this.state.modalUserData.roles, value:this.state.modalUserData.roles}} onChange={(t:any)=>{this.setState({modalUserData:{...this.state.modalUserData, roles:t.value}})}} options={[{label:'developer', value:'developer'}, {label:'admin', value:'admin'}]}></Select>
                            <label>Telegram</label>
                            <input value={this.state.modalUserData.telegram} onInput={(t)=>{this.setState({modalUserData:{...this.state.modalUserData, telegram:t.currentTarget.value}})}} placeholder='@my_tg' className='form-control'></input>
                            <hr></hr>
                            
                            {(this.state.modalType == 'show')?
                                <div>
                                    <button onClick={()=>{this.Update()}} className="btn btn-primary form-control">Update</button>
                                    <p></p>
                                    <button onClick={()=>{this.Delete()}} className="btn btn-danger form-control">Delete</button>
                                </div>
                                :
                                <button onClick={()=>{this.Add()}} className="btn btn-primary form-control"><i className="material-icons control_point c_p" style={{fontSize:16, margin:4}}></i>Add</button>
                            }
                        </div>
                    </CustomModal>
                </div>
            );
        }
    }
}

