import React, { useState, Component } from 'react';
import Select from 'react-select';
import {ApiReq, notySend} from '../components/function';



export default class DevelopersSelect extends Component<any, any> {
  constructor(props: any){
    super(props);
    this.state = {
        select_list:[],
        value:null,
    }
    
  }

  componentDidMount(){
    this.GetList();
  }

  

  GetList = () => {
    ApiReq('/users/get_users_developers/').then(res => {
        if(res != false){
            if(res.success == true){
                
                let select_list = [];
                let value = null;
                for(let i=0; i<res.data.length; i++){
                    select_list.push({ value: res.data[i].id, label: res.data[i].first_name+' '+res.data[i].last_name+' '+res.data[i].patronymic })
                    if(this.props.value != null){
                      if(res.data[i].id == this.props.value){
                        value = { value: res.data[i].id, label: res.data[i].first_name+' '+res.data[i].last_name+' '+res.data[i].patronymic };
                      }
                    }
                }
                
                this.setState({
                    select_list:select_list,
                    value:value
                })
            }
            else{
                notySend('error', 'error #'+res.error+'\n'+res.info);
            }
        }
    })
    
  }

  Change = (item:any) =>{
    if(this.props.onChange != null){
        this.props.onChange(item);
    }
    
    this.setState({value:item});
  }


  render() {
    return(
        <Select  value={this.state.value} onChange={(item:any)=>{this.Change(item)}} options={this.state.select_list} />
    )
  }
}


