import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate } from './function';
import Select from 'react-select'


export default class AppStatusSelect extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        select_data:[
            {label:'develop', value:'develop'},
            {label:'moderation', value:'moderation'},
            {label:'public', value:'public'},
            {label:'ban', value:'ban'},
        ],
        value:{label:'develop', value:'develop'}
      }
    }
  
    componentDidMount(){
      if(this.props.value != null){
        this.setState({
          value:this.props.value
        })
      } 
    }

    Change = (res:any) => {
        if(this.props.onChange != null){
            this.props.onChange(res);
            this.setState({
              value:res
            })
        }
    }
  
  
    render() {
      return(
        <Select value={this.state.value} onChange={(res)=>{this.Change(res)}}  options={this.state.select_data} />
      )
    }
}

