import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend } from '../components/function';
import Loading from '../pages/Loading';



export default class Header_page extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
          page:null,
          mini_menu:false,
          notifications_show:false,
          search_show:false,
      }
    }
  
    componentDidMount(){
      
      document.getElementsByClassName('overlay')[0].addEventListener("click", () =>{
        document.getElementById('mainwrapper')?.classList.remove('toggled');
      })

      this.RenderAlarmSubscrube();
    }
  
    LogOut = () => {
      ApiReq('/auth/logout/').then(res => {
        if(res != false){
          if(res.success == true){
            window.location.replace('/login/')
          }
          else{
            window.location.replace('/login/')
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
      localStorage.removeItem(window.location.host+'-us');
    }

    ShowNavigationMobile = () => {
      
      let classList:any = document.getElementById('mainwrapper')?.classList;
      document.getElementById('mainwrapper')?.classList.add('toggled');
      
    }

    RenderAlarmSubscrube = () => {
      let subscrube_info_from_checker:any = '{}';
      subscrube_info_from_checker = localStorage.getItem('subscrube_info_from_checker');
      if(subscrube_info_from_checker != null && subscrube_info_from_checker != undefined){
        subscrube_info_from_checker = JSON.parse(subscrube_info_from_checker);
        if(subscrube_info_from_checker.subscribe_type == 'rent'){
          const date1:any = new Date(subscrube_info_from_checker.date_to);
          const date2:any = new Date();
          const diffTime = Math.abs(date2 - date1);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          // console.log(diffDays + " days");
          if(diffDays < 7){
            return (
                <li className="nav-item " style={{marginRight:15}}>
                  <button onClick={()=>{notySend('error', 'your subscription expires in '+diffDays+' days!')}} style={{borderRadius:20, paddingTop:2, paddingBottom:2}} className='btn btn-outline-danger'>{diffDays} days</button>
                </li>
            )
          }
        }
      }
      
      
    }

    SetColorMode = (val:string) => {
      if(val == 'dark'){
        window.localStorage.setItem(window.location.origin+'_pixelcrm_theme', 'dark');
      }
      else{
        window.localStorage.setItem(window.location.origin+'_pixelcrm_theme', 'ligth');
      }
      window.location.reload();
    }

    SetMagicBg = () => {
      (window.localStorage.getItem(window.location.origin+'_pixelcrm_magic_bg') == null)?window.localStorage.setItem(window.location.origin+'_pixelcrm_magic_bg', 'true'):window.localStorage.removeItem(window.location.origin+'_pixelcrm_magic_bg')
      window.location.reload();
    }

    RenderColorModeIcon = () => {
      if(window.localStorage.getItem(window.location.origin+'_pixelcrm_theme') == null){
        return(
          <span className='c_p' onClick={()=>{this.SetColorMode('light')}}>
            <div className="apps p-2 radius-10 text-center">
              <i style={{marginTop:6}} className="material-icons wb_sunny text-warning c_p">&#xe430;</i>
              <p className="mb-0 apps-name text-white">Theme</p>
            </div>
          </span>
        )
      }
      else{
        if(window.localStorage.getItem(window.location.origin+'_pixelcrm_theme') == 'dark'){
          return(
            <span className='c_p' onClick={()=>{this.SetColorMode('light')}}>
              <div className="apps p-2 radius-10 text-center">
                <i style={{marginTop:6}} className="material-icons wb_sunny text-warning c_p">&#xe430;</i>
                <p className="mb-0 apps-name text-white">Theme</p>
              </div>
            </span>
          )
        }
        else{
          return(
            <span className='c_p' onClick={()=>{this.SetColorMode('dark')}}>
              <div className="apps p-2 radius-10 text-center">
                <i style={{marginTop:6}} className="material-icons wb_sunny text-dark c_p">&#xe430;</i>
                <p className="mb-0 apps-name text-white">Theme</p>
              </div>
            </span>
          )
        }
      }
    }

    RenderNotifications = () => {
      if(this.state.notifications_show == true){
        return(
          <div className="dropdown-menu dropdown-menu-end p-0 show" style={{right:0, top:60}}>
            <div className="p-2 border-bottom m-2">
                <h5 className="h5 mb-0">Notifications</h5>
            </div>
            <div className="header-notifications-list p-2" style={{overflowY:'scroll'}}>
                <div style={{background:(localStorage.getItem(window.location.origin+'_pixelcrm_theme') == 'dark')?'rgba(255, 255, 255, 0.1)':'rgba(0, 0, 0, 0.1)', padding:10, borderRadius:10, marginBottom:10}}>
                  <div style={{float:'right'}}><i className="material-icons highlight_off text-danger c_p">&#xe888;</i></div>
                  <strong>Title</strong>
                  <div style={{ height:'auto'}}>You have recived new orders ваша подписка истекает через 7 дней</div>
                </div>
            </div>
            
            <div className="p-2">
                <div><hr className="dropdown-divider"></hr></div>
                {/* <a className="dropdown-item" href="#">
                  <div className="text-center">Clear all Notifications</div>
                </a> */}
            </div>
          </div>
        )
      }
      else{
        return null;
      }
    }

    RenderDropdawnMenu = () => {
      return(
        <div className={this.state.mini_menu?'dropdown-menu dropdown-menu-end show':'dropdown-menu dropdown-menu-end'} data-bs-popper="static" style={{marginTop:5, marginRight:2}}>
          <div className="row row-cols-3 gx-2">
            <div className="col">
              <span onClick={()=>{this.SetMagicBg()}} className='c_p'>
                <div className="apps p-2 radius-10 text-center">
                    <i style={{marginTop:6, background: "linear-gradient(to right, #30CFD0 0%, #ff00e1 100%)", WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent'}} className="material-icons auto_fix_high c_p">&#xe663;</i>
                    <p className="mb-0 apps-name text-white">Magic BG</p>
                </div>
              </span>
            </div>
            <div className="col">
              {this.RenderColorModeIcon()}
            </div>
            <div className="col">
              <span className='c_p' onClick={()=>{window.location.reload()}}>
                <div className="apps p-2 radius-10 text-center">
                  <i style={{marginTop:6}} className="material-icons replay text-info c_p">&#xe042;</i>
                  <p className="mb-0 apps-name text-white">Reload</p>
                </div>
              </span>
            </div>
          </div>
      </div>
      )
    }

    ResetTableSearch = () => {
        for(let i=0; i<document.querySelectorAll('tbody tr').length; i++){
            document.querySelectorAll('tbody tr')[i].setAttribute('style', 'display:table-row');
        }
    }

    TableSearch = (text:string) => {
        this.ResetTableSearch();
        for(let i=0; i<document.querySelectorAll('tbody tr').length; i++){
            if(document.querySelectorAll('tbody tr')[i].textContent?.toLocaleLowerCase()?.indexOf(text.toLocaleLowerCase()) === -1){
                document.querySelectorAll('tbody tr')[i].setAttribute('style', 'display:none');
            }
            else{
                document.querySelectorAll('tbody tr')[i].setAttribute('style', 'display:table-row');
            }
        }
    }

    RenderSearchBox = () => {
      return(
        <div className={this.state.search_show?'dropdown-menu dropdown-menu-end show':'dropdown-menu dropdown-menu-end'} data-bs-popper="static" style={{marginTop:5, marginRight:2}}>
            <div className="row ">
                <div className="col">
                    <div className="apps p-2 radius-10">
                        {/* <span>Table search</span> */}
                        <input id='table_search' placeholder='Table search' onInput={(t)=>{this.TableSearch(t.currentTarget.value)}} className='form-control'></input>
                    </div>
                </div>
            </div>
        </div>
      )
  }
  
    render() {
      return (
        <header className="top-header">        
            <nav className="navbar navbar-expand gap-3">
                <div className="mobile-toggle-icon fs-3 ">
                    <i onClick={()=>{this.ShowNavigationMobile()}} className="material-icons notes">&#xe26c;</i>
                </div>
                <span className=''>{localStorage.getItem('page_name')}</span>
                <div className="top-navbar-right ms-auto">
                    <ul className="navbar-nav align-items-center">
                        <li className="nav-item dropdown dropdown-large" >
                          <span className="nav-link dropdown-toggle dropdown-toggle-nocaret"  data-bs-toggle="dropdown" style={{marginTop:6}}>
                            <div className="notifications">
                                <i onClick={()=>{this.setState({search_show:!this.state.search_show}); this.ResetTableSearch(); setTimeout(()=>{document.getElementById('table_search')?.focus()},300)}} className="material-icons search c_p text-info">&#xe8b6;</i>
                            </div>
                          </span>
                          {this.RenderSearchBox()}
                        </li>
                        <li className="nav-item " style={{marginRight:10}}>
                            <i style={{marginTop:6}}  onClick={()=>{window.location.reload()}} className="material-icons apps replay text-info c_p">&#xe042;</i>                        
                        </li>
                        <li className="nav-item ">
                            <i style={{marginTop:6}}  onClick={()=>{this.LogOut()}} className="material-icons power_settings_new text-danger c_p"></i>                          
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
      );
    }
}




