import React, { Component } from 'react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, encryptAES, isJson, spinner } from '../components/function';
import Loading from '../pages/Loading';
import DevelopersSelect from '../components/DevelopersSelect';
import Select from 'react-select';
import AppsSelectList from '../components/AppsSelectList';
import GeoSelectList from '../components/GeoListSelect';



export default class TransferEventsPage extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            page_load:false,
            btn_load:false,
            item_transfer_show:true,
            item_export_show:false,

            export_apps:null,
            export_quantity:500,
            export_geo:null,
            export_response:'',
            export_btn_run_transfer:false,

            transfer_app:null,
            transfer_platform:{label:'AppsFlyer', value:'AppsFlyer'},
            transfer_geo:null,
            transfer_quantity:200,
            transfer_app_data:{app:{package:'', appsflyer_key:'', fb_app_id:''}, events:[]},
            transfer_total_events:0,
            transfer_left_time:0,
            transfer_success_reg:0,
            transfer_success_dep:0,
        }
    }
  
    componentDidMount(){
        
    }



    TransferEvents = (from:string='') => {
        if(this.state.transfer_app != null && this.state.transfer_quantity > 0){
            let geo:any = null;
            let requestBody:any = {app:this.state.transfer_app, quantity:(from === 'export')?1:this.state.transfer_quantity, platform:this.state.transfer_platform.value, return_data:true};
            if(this.state.transfer_geo != null){
                geo = [];
                for(let i=0; i<this.state.transfer_geo.length; i++){
                    geo.push(this.state.transfer_geo[i].value)
                }
                requestBody.geo = geo;
            }
            this.setState({btn_load:true})
            ApiReq('/postback_event/transfer_events/', requestBody).then(res => {
                if(res != false){
                    if(res.success == true){
                        if(from === 'export'){
                            let exportEvents = JSON.parse(this.state.export_response);
                            this.setState({
                                transfer_app_data:{app:res.app, events:exportEvents},
                                transfer_total_events:exportEvents.length,
                                transfer_left_time:exportEvents.length * 2
                            })
                        }
                        else{
                            this.setState({
                                transfer_app_data:{app:res.app, events:res.events},
                                transfer_total_events:res.events.length,
                                transfer_left_time:res.events.length * 2
                            })
                        }
                        

                        let temp = 0;
                        let interval = setInterval(() => {
                            if(temp < this.state.transfer_app_data.events.length){
                                let body = this.state.transfer_app_data.events[temp];
                                body.tracker = this.state.transfer_platform.value;
                                body.hash = body.device_id;
                                body.appsflyer_key = this.state.transfer_app_data.app.appsflyer_key;
                                body.fb_app_id = this.state.transfer_app_data.app.fb_app_id;
                                body.app = this.state.transfer_app_data.app.package;
                                body.event = 'reg';
                                // console.log(body)
                                ApiReq('/postback_event/send_user_event/', body).then(res => {
                                    console.log(res)
                                    if(res != false){
                                        if(res.success == true){
                                            this.setState({transfer_success_reg:this.state.transfer_success_reg + 1})

                                            //for event dep
                                            body.event = 'dep';
                                            ApiReq('/postback_event/send_user_event/', body).then(res => {
                                                console.log(res)
                                                if(res != false){
                                                    if(res.success == true){
                                                        this.setState({transfer_success_dep:this.state.transfer_success_dep + 1})
                                                    }
                                                }
                                            })
                                        }
                                    }
                                    this.setState({transfer_left_time:this.state.transfer_left_time - 2})
                                })
                                temp++;
                            }
                            else{
                                clearInterval(interval);
                                this.setState({btn_load:false})
                            }
                        }, 2000)
                    }
                    else{
                        this.setState({btn_load:false})
                        notySend('error', 'error #'+res.error+'\n');
                    }
                }
                else{
                    this.setState({btn_load:false})
                }
            })
        }
        else{
            notySend('error', 'app must not be empty and quantity must be > 0!');
        }
    }

    
    
    GetExportEventsData = () => {
        let apps:any = null;
        let geo:any = null;
        let quantity = this.state.export_quantity;
        let requestBody:any = {quantity:quantity};
        if(this.state.export_apps != null){
            apps = [];
            for(let i=0; i<this.state.export_apps.length; i++){
                apps.push(this.state.export_apps[i].value)
            }
            requestBody.apps = apps;
        }
        if(this.state.export_geo != null){
            geo = [];
            for(let i=0; i<this.state.export_geo.length; i++){
                geo.push(this.state.export_geo[i].value)
            }
            requestBody.geo = geo;
        }
        if(typeof quantity == 'number'){
            this.setState({btn_load:true})
            ApiReq('/postback_event/export_data_for_transfer_events/', requestBody).then(res => {
                if(res != false){
                    if(res.success == true){
                        this.setState({
                            export_response:JSON.stringify(res.data, undefined, 4)
                        })
                        notySend('success', 'success');
                        setTimeout(()=>{
                            this.CheckValidExportData();
                        }, 200)
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n');
                    }
                }
                this.setState({btn_load:false})
            })
        }
        else{
            notySend('error', 'quantity must be number!');
        }
    }

    CheckValidExportData = () => {
        if(this.state.export_response != ''){
            if(isJson(this.state.export_response)){
                this.setState({
                    export_btn_run_transfer:true
                })
            }
            else{
                this.setState({
                    export_btn_run_transfer:false
                })
            }
        }
        else{
            this.setState({
                export_btn_run_transfer:false
            })
        }
    }
  
    render() {
        return (
            <div className='page-content'>
                <div className="card">
                    <div className="card-body">
                        <div className="card">
                            <div className="card-header c_p" style={{borderBottom:'none'}} onClick={()=>{this.setState({item_transfer_show:!this.state.item_transfer_show})}}>
                                <a className="card-link" data-toggle="collapse" >
                                    <strong>Transfer events</strong>
                                </a>
                            </div>
                            <div  className={this.state.item_transfer_show?"collapse show":"collapse"}>
                                <div className="card-body" style={{minHeight:450}}>
                                    <div className="row">
                                        <div className='col'>
                                            <label>*App</label>
                                            <AppsSelectList onChange={(t:any)=>{this.setState({transfer_app:t})}}></AppsSelectList>
                                        </div>
                                        <div className='col'>
                                            <label>*Transfer platform</label>
                                            <Select value={this.state.transfer_platform} onChange={(t:any)=>{this.setState({transfer_platform:t})}} options={[{label:'AppsFlyer', value:'AppsFlyer'}, {label:'Facebook', value:'Facebook'}]}></Select>
                                        </div>
                                        <div className='col'>
                                            <label>Geo (optional)</label>
                                            <GeoSelectList onChange={(t:any)=>{this.setState({transfer_geo:t})}} isMulti={true}></GeoSelectList>
                                        </div>
                                        <div className='col'>
                                            <label>*Quantity</label>
                                            <input value={this.state.transfer_quantity} onInput={(t)=>{this.setState({transfer_quantity:Number(t.currentTarget.value)})}} className='form-control' type='number'></input>
                                        </div>
                                        <div className='col'>
                                            <label>Run transfer</label>
                                            <button onClick={()=>{this.TransferEvents()}} disabled={this.state.btn_load} className='btn btn-primary form-control'>{this.state.btn_load?spinner():null} Run transfer</button>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="alert alert-secondary">
                                        <strong>Total events:{this.state.transfer_total_events} , time send: every 2 sec , time left: {this.state.transfer_left_time} sec {this.state.btn_load?spinner():null}</strong>
                                        <hr></hr>
                                        <div className='table-responsive' style={{padding:2}}>
                                            <table className='table table-hover'>
                                                <tbody>
                                                    <tr>
                                                        <td>App package</td>
                                                        <td>{this.state.transfer_app_data.app.package}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Platform (tracker)</td>
                                                        <td>{this.state.transfer_platform.value}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>AppsFlyer key</td>
                                                        <td>{this.state.transfer_app_data.app.appsflyer_key}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Facebook app id</td>
                                                        <td>{this.state.transfer_app_data.app.fb_app_id}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Success reg events</td>
                                                        <td>{this.state.transfer_success_reg}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Success dep events</td>
                                                        <td>{this.state.transfer_success_dep}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header c_p" style={{borderBottom:'none'}} onClick={()=>{this.setState({item_export_show:!this.state.item_export_show})}}>
                                <a className="card-link" data-toggle="collapse" >
                                    <strong>Export events data</strong>
                                </a>
                            </div>
                            <div  className={this.state.item_export_show?"collapse show":"collapse"}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className='col'>
                                            <label>Apps (optional)</label>
                                            <AppsSelectList onChange={(t:any)=>{this.setState({export_apps:t});}} isMulti={true}></AppsSelectList>
                                        </div>
                                        <div className='col'>
                                            <label>*Quantity</label>
                                            <input value={this.state.export_quantity} onInput={(t)=>{this.setState({export_quantity:Number(t.currentTarget.value)})}} className='form-control' type='number'></input>
                                        </div>
                                        <div className='col'>
                                            <label>Geo (optional)</label>
                                            <GeoSelectList onChange={(t:any)=>{this.setState({export_geo:t})}} isMulti={true}></GeoSelectList>
                                        </div>
                                        <div className='col'>
                                            <label>Export</label>
                                            <button disabled={this.state.btn_load} onClick={()=>{this.GetExportEventsData()}}  className='btn btn-primary form-control'>{this.state.btn_load?spinner():null} Export</button>
                                        </div>
                                    </div>
                                    {
                                        this.state.export_btn_run_transfer?
                                            <button disabled={this.state.btn_load} onClick={()=>{this.TransferEvents('export')}} className='btn btn-outline-primary form-control' style={{marginTop:10}}>{this.state.btn_load?spinner():null} Run transfer from export</button>
                                        :
                                            null
                                    }
                                    <hr></hr>
                                    <textarea value={this.state.export_response} onInput={(t)=>{this.setState({export_response:t.currentTarget.value}); setTimeout(()=>{this.CheckValidExportData()}, 150)}} className='form-control' style={{minHeight:450}}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

