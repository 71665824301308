import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, urlNavigate, getUrlPath } from '../components/function';
import Loading from '../pages/Loading';
import CustomModal from '../components/CustomModal';
import AppsSelectList from '../components/AppsSelectList';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
ChartJS.defaults.color = 'black';
const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
            color: 'black',
        },
      },
      title: {
        display: false,
      },
    },
  };

  let labels:any = [];
  let chart_data:any = {
    labels,
    datasets: [
      {
        label: 'Reg',
        data: [],
        backgroundColor: 'rgba(204, 191, 2, 0.5)',
      },
      {
        label: 'Dep',
        data: [],
        backgroundColor: 'rgba(16, 163, 0, 0.5)',
      },
    ],
};


export default class AnalyticsConversionPage extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            app:'',
            page_load:null,
        }
    }
  
    componentDidMount(){
       
    }

    GetData = () => {
        if(this.state.app !== ''){
            this.setState({
                page_load:false
            })
            ApiReq('/analytics_conversion/get/', {app:this.state.app}).then(res => {
                if(res != false){
                    if(res.success == true){
                        if(res.data.length != 0){
                            let regs:any = [];
                            let deps:any = [];
                            let new_labels:any = [];
                            
                            for(let i=0; i<res.data.length; i++){
                                new_labels.push(res.data[i].geo);
                                // labels.push(res.data[i].geo);
                                regs.push(res.data[i].reg);
                                deps.push(res.data[i].dep);
                            }
                            
                            chart_data.datasets[0].data = regs;
                            chart_data.datasets[1].data = deps;
                            chart_data.labels = new_labels;
                            // setTimeout(()=>{
                            //     chart_data.datasets[0].data = regs;
                            //     chart_data.datasets[1].data = deps;
                            //     chart_data.labels = new_labels;
                            //     // labels = new_labels;
                            //     // setTimeout(()=>{
                            //     //   this.setState({
                            //     //     status:'success'
                            //     //   })
                            //     // }, 200)
                                
                            // }, 1000)
                        }
    
                        this.setState({
                            page_load:true
                        })
                    }
                    else{
                        notySend('error', 'error #'+res.error);
                    }
                }
            })
        }
        else{
            notySend('error', 'select app!');
        }
    }

    RenderChart = () => {
        if(this.state.page_load == false){
            return <div className='text-center'><Loading></Loading></div>;
        }
        else{
            if(this.state.page_load == null){
                return <div style={{minHeight:400}}></div>;
            }
            else{
                return <Bar style={{maxHeight:400}} options={options} data={chart_data} />;
            }
        }
      }

    
    render() {
        return (
            <div className='page-content'>
                <div className="card">
                    <div className='card-body'>
                        <div className="row">
                            <div className="col">
                                <label>App</label>
                                <AppsSelectList onChange={(item:any)=>{this.setState({app:item});}} ></AppsSelectList>
                            </div>
                            <div className="col">
                                <label>Get report</label>
                                <button onClick={()=>{this.GetData()}} className="btn btn-primary form-control">Get</button>
                            </div>
                        </div>
                        <hr></hr>
                        {this.RenderChart()}
                    </div>
                </div>
            </div>
        );
    }
}

