import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, urlNavigate, getUrlPath, isJson, spinner, convertUTCTimeToLocalTime } from '../components/function';
import AppStatusSelect from '../components/AppStatusSelect';
import Loading from './Loading';
import GeoListSelect from '../components/GeoListSelect';
import CustomModal from '../components/CustomModal';
import AppGroupsSelect from '../components/AppGroupsSelect';
import DevelopersSelect from '../components/DevelopersSelect';
import AppsSelectList from '../components/AppsSelectList';
import { FileUploader } from "react-drag-drop-files";
import Select from 'react-select';
const s_options = [
  { value: true, label: 'true' },
  { value: false, label: 'false' },
]

let url = getUrlPath();

export default class AppPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        customModal:false,
        page_load:false,
        btn_loader:false,
        app_data:{name:'', package:'', store:'', comment:'', host:'', appsflyer_key:'', onesignal_app_id:'', onesignal_api_key:''},
        page_menu:[
            {name:'Home', icon:<i className="material-icons home" style={{fontSize:15, lineHeight:2}}></i>, activ:true},
            {name:'rganic', icon:<i className="material-icons panorama_fish_eye" style={{fontSize:15, lineHeight:2}}></i>, activ:false},
            {name:'Naming', icon:<i className="material-icons mode_standby" style={{fontSize:15, lineHeight:2}}></i>, activ:false},
            {name:'Accounts', icon:<i className="material-icons shield" style={{fontSize:15, lineHeight:2}}></i>, activ:false},
            {name:'Notifications', icon:<i className="material-icons unsubscribe" style={{fontSize:15, lineHeight:2}}></i>, activ:false},
            {name:'Testing', icon:<i className="material-icons slow_motion_video" style={{fontSize:15, lineHeight:2}}></i>, activ:false},
        ],
        delete_modal:false,
        delete_modal_secret_password:'',

        organics_data:[],
        add_organic_modal:false,
        add_organic_data:{event:'add', app_package:url[1], url:'', geo:'EN'},
        update_organic_data:{event:'update', app_package:url[1], new_url:'', geo:'EN'},
        onelink_organic_modal:false,
        onelink_organic:{activ:false, url:''},

        namings_data:[],
        add_naming_modal:false,
        add_naming_data:{event:'add', app_package:url[1], url:'', naming:''},
        update_naming_data:{event:'update', app_package:url[1], new_url:'', naming:''},
        onelink_naming_modal:false,
        onelink_naming:{activ:false, url:''},
        reserve_onelink_naming_modal:false,
        reserve_onelink_naming:{activ:false, url:''},

        accounts_data:{fb_login:'', fb_pass:'', fb_app_id:'', appsflyer_login:'',appsflyer_pass:'',appsflyer_jwt:'',octo_number:'',octo_profile_id:''},
        
        noti_conn_sett_show:false,
        noti_send_noti_show:false,
        noti_latest_noti_show:false,
        noti_connect_data:{success:false, data:{package:url[1], fcm_server_key:'', fcm_json:''}},
        noti_send_data:{naming:'', event:{label:'installation', value:'installation'}, geo:null, title:'', body:'', icon:''},
        noti_loader_lastes_data:true,
        noti_lastes_data:[],
        testing_list:null,
        testing_form:{version:'0.1', comment:''}
      }
    }
  
    componentDidMount(){
        this.GetAppData();
    }
  
    // for app
    GetAppData = () => {
        ApiReq('/apps/get_apps_data/', {select:['name', 'package', 'store', 'status', 'activ', 'production', 'count_installs', 'icon', 'host', 'appsflyer_key', 'onesignal_app_id', 'onesignal_api_key', 'developer', 'group', 'production_type', 'comment', 'platform', 'create_time', 'moderation_time', 'public_time', 'ban_time'], where:[{package:[url[1]]}], limit:this.state.limit}).then(res => {
            if(res != false){
              if(res.success == true){
                this.setState({
                    app_data:res.data[0],
                    page_load:true,
                    btn_load_more:true,
                })
              }
              else{
                notySend('error', 'error #'+res.error+'\n')
              }
            }
        })
    }
    UpdateAppData = () => {
        this.setState({
            btn_loader:true,
        })
        let app_data = this.state.app_data;
        let update_data:any = {app:url[1], update:{}};
        
        for (const key in app_data) {
            if(key != 'id' && key != 'platform' && key != 'count_installs' && key != 'moderation_time' && key != 'create_time' && key != 'public_time' && key != 'ban_time' && key != 'testing' && key != 'package'){
                update_data.update[key] = app_data[key];
            }
        }
        if(url[1] != this.state.app_data.package){
            update_data.update.package = this.state.app_data.package;
        }
        //console.log(update_data)
        ApiReq('/apps/update/', update_data).then(res => {
            if(res != false){
              if(res.success == true){
                //console.log(res)
                if(this.state.app_data.package != url[1]){
                    urlNavigate('/app/'+this.state.app_data.package+"/");
                }
                notySend('success', 'success')
              }
              else{
                notySend('error', 'error #'+res.error+'\n');
              }
              this.setState({
                btn_loader:false,
              })
            }
        })
    }
    Delete = () => {
        ApiReq('/apps/delete/', {app:url[1], secret_password:this.state.delete_modal_secret_password}).then(res => {
            if(res != false){
              if(res.success == true){
                urlNavigate('/apps/'+this.state.app_data.platform+'/public/');
              }
              else{
                notySend('error', 'error #'+res.error+'\n')
              }
            }
        })
    }

    GetAppStoreUrl = () => {
        if(this.state.app_data.platform === 'android'){
            if(this.state.app_data.store === 'GOOGLE_PLAY'){
                return 'https://play.google.com/store/apps/details?id='+url[1];
            }
            if(this.state.app_data.store === 'MI_STORE'){
                return 'https://global.app.mi.com/details?id='+url[1];
            }
            return 'app store url not found';
        }
        else{
            return 'https://apps.apple.com/ru/app/id'+url[1];
        }
    }



    // for organics
    GetOrganics = () => {
        ApiReq('/apps/organics/', {event:'get', app_package:url[1]}).then(res => {
            if(res != false){
              if(res.success == true){
                this.setState({
                    organics_data:res.data,
                    page_load:true,
                })
              }
              else{
                notySend('error', 'error #'+res.error+'\n')
              }
            }
        })
    }
    AddOrganic = () => {
        ApiReq('/apps/organics/', this.state.add_organic_data).then(res => {
            if(res != false){
              if(res.success == true){
                this.GetOrganics();
                notySend('success', 'success');
              }
              else{
                notySend('error', 'error #'+res.error+'\n');
              }
            }
        })
    }
    UpdateOrganic = () => {
        ApiReq('/apps/organics/', this.state.update_organic_data).then(res => {
            if(res != false){
              if(res.success == true){
                notySend('success', 'success');
              }
              else{
                notySend('error', 'error #'+res.error+'\n');
              }
            }
        })
    }
    DeleteOrganic = (geo:string) => {
        ApiReq('/apps/organics/', {event:'delete', app_package:url[1], geo:geo}).then(res => {
            if(res != false){
              if(res.success == true){
                this.GetOrganics();
                notySend('success', 'success');
              }
              else{
                notySend('error', 'error #'+res.error+'\n');
              }
            }
        })
    }
    GetOnelinkOrganic = () => {
        if(getUserData('roles').indexOf('admin') !== -1){
            ApiReq('/apps/organic_one_link/', {event:'get', app_package:url[1]}).then(res => {
                if(res != false){
                  if(res.success == true){
                    this.setState({
                        onelink_organic:res.data,
                    })
                  }
                  else{
                    notySend('error', 'error #'+res.error+'\n')
                  }
                }
            })
        }
    }
    UpdateOnelinkOrganic = () => {
        ApiReq('/apps/organic_one_link/', {event:'update', app_package:url[1], url:this.state.onelink_organic.url, activ:this.state.onelink_organic.activ}).then(res => {
            if(res != false){
              if(res.success == true){
                notySend('success', 'success')
                this.GetOnelinkOrganic();
              }
              else{
                notySend('error', 'error #'+res.error+'\n')
              }
            }
        })
    }



    // for namings
    GetNamings = () => {
        ApiReq('/apps/namings/', {event:'get', app_package:url[1]}).then(res => {
            if(res != false){
              if(res.success == true){
                this.setState({
                    namings_data:res.data,
                    page_load:true,
                })
              }
              else{
                notySend('error', 'error #'+res.error+'\n')
              }
            }
        })
    }
    AddNaming = () => {
        ApiReq('/apps/namings/', this.state.add_naming_data).then(res => {
            if(res != false){
              if(res.success == true){
                this.GetNamings();
                notySend('success', 'success');
              }
              else{
                notySend('error', 'error #'+res.error+'\n');
              }
            }
        })
    }
    UpdateNaming = () => {
        ApiReq('/apps/namings/', this.state.update_naming_data).then(res => {
            if(res != false){
              if(res.success == true){
                notySend('success', 'success');
              }
              else{
                notySend('error', 'error #'+res.error+'\n');
              }
            }
        })
    }
    DeleteNaming = (naming:string) => {
        ApiReq('/apps/namings/', {event:'delete', app_package:url[1], naming:naming}).then(res => {
            if(res != false){
              if(res.success == true){
                this.GetNamings();
                notySend('success', 'success');
              }
              else{
                notySend('error', 'error #'+res.error+'\n');
              }
            }
        })
    }
    GetOnelinkNaming = () => {
        if(getUserData('roles').indexOf('admin') !== -1){
            ApiReq('/apps/naming_one_link/', {event:'get', app_package:url[1]}).then(res => {
                if(res != false){
                  if(res.success == true){
                    this.setState({
                        onelink_naming:res.data,
                    })
                  }
                  else{
                    notySend('error', 'error #'+res.error+'\n')
                  }
                }
            })
        }
    }
    UpdateOnelinkNaming = () => {
        ApiReq('/apps/naming_one_link/', {event:'update', app_package:url[1], url:this.state.onelink_naming.url, activ:this.state.onelink_naming.activ}).then(res => {
            if(res != false){
              if(res.success == true){
                notySend('success', 'success')
                this.GetOnelinkNaming();
              }
              else{
                notySend('error', 'error #'+res.error+'\n')
              }
            }
        })
    }
    GetReserveOnelinkNaming = () => {
        if(getUserData('roles').indexOf('admin') !== -1){
            ApiReq('/apps/reserv_naming_one_link/', {event:'get', app_package:url[1]}).then(res => {
                if(res != false){
                  if(res.success == true){
                    this.setState({
                        reserve_onelink_naming:res.data,
                    })
                  }
                  else{
                    notySend('error', 'error #'+res.error+'\n')
                  }
                }
            })
        }
    }
    UpdateReserveOnelinkNaming = () => {
        ApiReq('/apps/reserv_naming_one_link/', {event:'update', app_package:url[1], url:this.state.reserve_onelink_naming.url, activ:this.state.reserve_onelink_naming.activ}).then(res => {
            if(res != false){
              if(res.success == true){
                notySend('success', 'success')
                this.GetReserveOnelinkNaming();
              }
              else{
                notySend('error', 'error #'+res.error+'\n')
              }
            }
        })
    }


    //for accounts
    GetAccounts = () => {
        ApiReq('/apps/accounts/', {event:'get', app_package:url[1]}).then(res => {
            if(res != false){
              if(res.success == true){
                this.setState({
                    accounts_data:res.data,
                    page_load:true,
                })
              }
              else{
                notySend('error', 'error #'+res.error+'\n')
              }
            }
        })
    }
    UpdateAccounts = () => {
        let accounts_data = this.state.accounts_data;
        accounts_data.event = 'update';
        accounts_data.app_package = url[1];
        ApiReq('/apps/accounts/', accounts_data).then(res => {
            if(res != false){
              if(res.success == true){
                notySend('success', 'success');
              }
              else{
                notySend('error', 'error #'+res.error+'\n')
              }
            }
        })
    }


    //for notifications
    GetNotificationsData = () => {
        this.setState({noti_loader_lastes_data:true})
        ApiReq('/noti_service/get_data/', {app_package:url[1]}).then(res => {
            if(res != false){
              if(res.success == true){
                if(res.status.success){
                    this.setState({
                        noti_connect_data:res.status,
                        noti_lastes_data:res.last_notifications.data,
                        noti_loader_lastes_data:false
                    })
                }
                this.setState({
                    page_load:true,
                })
              }
              else{
                notySend('error', 'error #'+res.error+'\n')
              }
            }
        })
    }
    ConnectAppToPushService = () => {
        if(this.state.noti_connect_data.fcm_server_key != '' && this.state.noti_connect_data.fcm_json != ''){
            this.setState({btn_loader:false})
            ApiReq('/noti_service/connect_app_to_push_service/', {app_package:url[1], fcm_server_key:this.state.noti_connect_data.data.fcm_server_key, fcm_json:this.state.noti_connect_data.data.fcm_json}).then(res => {
                if(res != false){
                  if(res.success == true){
                    notySend('success', 'success!');
                    this.GetNotificationsData();
                  }
                  else{
                    notySend('error', 'error #'+res.error+'\n');
                  }
                }
                this.setState({btn_loader:false});
            })
        }
        else{
            notySend('error', 'fcm server key and fcm json must not be empty!')
        }
    }
    UpdateNotiAppData = () => {
        if(this.state.noti_connect_data.fcm_server_key != '' && this.state.noti_connect_data.fcm_json != ''){
            this.setState({btn_loader:true})
            ApiReq('/noti_service/update_app_in_push_service/', {app_package:url[1], fcm_server_key:this.state.noti_connect_data.data.fcm_server_key, fcm_json:this.state.noti_connect_data.data.fcm_json}).then(res => {
                if(res != false){
                  if(res.success == true){
                    notySend('success', 'success!');
                    this.GetNotificationsData();
                  }
                  else{
                    notySend('error', 'error #'+res.error+'\n')
                  }
                } 
                this.setState({btn_loader:false});
            })
        }
        else{
            notySend('error', 'fcm server key and fcm json must not be empty!')
        }
    }
    SendNoti = () => {
        if(this.state.noti_send_data.title != '' && this.state.noti_send_data.body != ''){
            this.setState({btn_loader:true})
            ApiReq('/noti_service/send_noti/', {app_package:url[1], type:this.state.noti_send_data.event.value, push_body:this.state.noti_send_data.body, push_title:this.state.noti_send_data.title, geo:this.state.noti_send_data.geo, image:this.state.noti_send_data.icon}).then(res => {
                if(res != false){
                  if(res.success == true){
                    notySend('success', 'success');
                    this.GetNotificationsData();
                  }
                  else{
                    notySend('error', 'error #'+res.error+'\n');
                  }
                } 
                console.log(res)
                this.setState({btn_loader:false});
            })
        }
        else{
            notySend('error', 'title and body must not be empty!')
        }
    }

    //for testing
    GetTestingList = () => {
        ApiReq('/apps/testing/', {app_package:url[1], event:'get_testing'}).then(res => {
            console.log(res)
            if(res != false){
                if(res.success == true){
                    this.setState({
                        testing_list:res.data
                    })
                }
                else{
                    notySend('error', 'error #'+res.error+'\n');
                }
            } 
        })
    }

    AddTesting = (test_status:String) => {
        if(this.state.testing_form.version != ''){
           ApiReq('/apps/testing/', {app_package:url[1], version:this.state.testing_form.version, comment:this.state.testing_form.comment, tester_email:getUserData('loginName'), status:test_status, event:'add_testing'}).then(res => {
                if(res != false){
                    if(res.success == true){
                        notySend('success', 'success');
                        this.GetTestingList();
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n');
                    }
                } 
            })
        }
        else{
            notySend('error', 'version must be not empty!');
        }
    }

    RenderTestingList = () => {
        if(this.state.testing_list == null){
            return(
                <div className="text-center">
                    <div className="spinner-border text-primary"></div>
                </div>
            )
        }
        else{
            if(this.state.testing_list.length == 0){
                return(
                    <div className="alert alert-warning text-center">
                        <strong>this app has not been tested yet!</strong>
                    </div>
                )
            }
            else{
                return(
                    <div className="list-group">
                        <div style={{padding:1}} className='table-responsive '>
                            <table style={{padding:0, margin:0}} className="table table-bordered ">
                                <thead >
                                    <tr>
                                        <th>version</th>
                                        <th>status</th>
                                        <th>date</th>
                                        <th>tester</th>
                                        <th>comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.testing_list.map((item:any) => {
                                        return(
                                            <tr key={item.id}>
                                                <td>{item.version}</td>
                                                <td><span style={{borderRadius:'10rem'}} className={(item.status == 'reject')?'badge badge-pill bg-warning':'badge badge-pill bg-success'}>{item.status}</span></td>
                                                <td>{item.dt}</td>
                                                <td> <span>email: {item.tester_email}</span></td>
                                                <td>{item.comment}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            }
        }
    }

  SetTestingVersion = () => {
    let testing_form = this.state.testing_form;
    if(this.state.testing_list != null && this.state.testing_list.length != 0){
        let c_v = Number(this.state.testing_list[this.state.testing_list.length -1].version);
        
        testing_form.version = c_v + 0.1;
        testing_form.version = testing_form.version.toFixed(1);
        this.setState({
            testing_form:testing_form
        })
    }
  }

  RenderTestingForm = () => {
    let form = 
    <div className='card'>
        <div className='card-body'>
            <div className='form-group'>
                <label>tester : {getUserData('loginName')}</label>
                <br></br>
                <label>version</label>
                <input value={this.state.testing_form.version} onInput={(t)=>{let d=this.state.testing_form; d.version = t.currentTarget.value; this.setState({testing_form:d})}} className='form-control'></input>
                <label>comment</label>
                <textarea value={this.state.testing_form.comment} onInput={(t)=>{let d=this.state.testing_form; d.comment = t.currentTarget.value; this.setState({testing_form:d})}} className='form-control'></textarea>
                <br></br>
                <div className='row'>
                    <div className='col'>
                        <button onClick={()=>{this.AddTesting('reject')}} className='btn btn-warning form-control'>reject</button>
                    </div>
                    <div className='col'>
                        <button onClick={()=>{this.AddTesting('approve')}} className='btn btn-success form-control'>approve</button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
    if(getUserData('roles').indexOf('admin') != -1 || getUserData('roles').indexOf('tester') != -1){
        if(this.state.testing_list == null || this.state.testing_list.length == 0){
            return form;
        }
        else{
            if(this.state.testing_list[this.state.testing_list.length - 1].status != 'approve'){
                return form;
            }
            else{
                return null;
            }
        }
    }
    else{
        return null;
    }
  }


    // for navigation in page
    HideModal = () => {
        this.setState({
          customModal:false
        })
    }

    SetPageMenu = (name:string) => {
        let page_menu = this.state.page_menu;
        for (let i = 0; i < page_menu.length; i++) {
            if(page_menu[i].name != name) {
                page_menu[i].activ = false;
            }
            else{
                page_menu[i].activ = true;
                if(page_menu[i].name == 'Home'){
                    this.GetAppData();
                }
                if(page_menu[i].name == 'rganic'){
                    this.GetOrganics();
                    this.GetOnelinkOrganic();
                }
                if(page_menu[i].name == 'Naming'){
                    this.GetNamings();
                    this.GetOnelinkNaming();
                    this.GetReserveOnelinkNaming();
                }
                if(page_menu[i].name == 'Accounts'){
                    this.GetAccounts();
                }
                if(page_menu[i].name == 'Notifications'){
                    this.GetNotificationsData();
                }
                if(page_menu[i].name == 'Testing'){
                    this.GetTestingList();
                }
            }
        }
        
        this.setState({page_menu:page_menu, page_load:false})
    }

    UpdateStateAppData = (param:string, value:any) => {
        let app_data = this.state.app_data;
        app_data[param] = value;
        this.setState({app_data:app_data})
    }
    UpdateStateAddOrganicData = (param:string, value:any) => {
        let add_organic_data = this.state.add_organic_data;
        add_organic_data[param] = value;
        this.setState({add_organic_data:add_organic_data})
    }
    UpdateStateUpdateOrganicData = (param:string, value:any) => {
        let update_organic_data = this.state.update_organic_data;
        update_organic_data[param] = value;
        this.setState({update_organic_data:update_organic_data})
    }

    UpdateStateAddNamingData = (param:string, value:any) => {
        let add_naming_data = this.state.add_naming_data;
        add_naming_data[param] = value;
        this.setState({add_organic_data:add_naming_data})
    }
    UpdateStateUpdateNamingData = (param:string, value:any) => {
        let update_naming_data = this.state.update_naming_data;
        update_naming_data[param] = value;
        this.setState({update_organic_data:update_naming_data})
    }
    UpdateStateNotiSendData = (param:string, value:any) => {
        let noti_send_data = this.state.noti_send_data;
        noti_send_data[param] = value;
        this.setState({noti_send_data:noti_send_data})
    }
    UpdateStateNotiConnectData = (param:string, value:any) => {
        let noti_connect_data = this.state.noti_connect_data;
        noti_connect_data.data[param] = value;
        this.setState({noti_connect_data:noti_connect_data})
        // console.log(noti_connect_data)
    }

    RenderOrganic = () => {
        if(!this.state.page_load){
            return(
                <div className='text-center'><Loading></Loading></div>
            )
        }
        else{
            return(
                <>
                    <div key={'organic'} className='row'>
                        <div className='col'>
                            {/* <label>Onelink organic</label>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><div className="spinner-grow spinner-grow-sm text-success"></div> activ</span>
                                </div>
                                <Select value={{label:String(this.state.onelink_organic.activ), value:this.state.onelink_organic.activ}} onChange={(t:any)=>{let oon=this.state.onelink_organic; oon.activ=t.value;this.setState({onelink_organic:oon})}} options={s_options}></Select>
                                <input placeholder='Write url' value={this.state.add_naming_data.url} onInput={(t)=>{this.UpdateStateAddOrganicData('url', t.currentTarget.value)}} className='form-control'></input>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-primary" type="button">Update</button>
                                </div>
                            </div> */}
                            <button onClick={()=>{this.setState({onelink_organic_modal:true})}} className={this.state.onelink_organic.activ?'btn btn-outline-success form-control m-1':'btn btn-outline-danger form-control m-1'}>Onelink Organic</button>
                        </div>
                        <div className='col'>
                            <button onClick={()=>{this.setState({add_organic_modal:true})}} className='btn btn-primary form-control m-1'>Add organic</button>
                        </div>
                    </div>
                    <br></br>
                    <div className='table-responsive' style={{padding:2}}>
                        <table className="table table-striped table-bordered text-center">
                            <thead>
                                <tr>
                                    <th>Geo</th>
                                    <th>Url</th>
                                    <th>Update</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.organics_data.map((item:any) => this.OrganicTableItem(item))}
                            </tbody>
                        </table>
                    </div>
                </>
            )
        }
    }
    OrganicTableItem = (item:any) => {
        return(
            <tr key={item.geo}>
                <td style={{color:(item.customer != '0')?'#ec4561':'', textAlign:'left', minWidth:180}}><img width="35" height="35" style={{borderRadius:15}} src={item.img}></img> {item.geo_name+' ('+item.geo+')'}</td>
                <td><input defaultValue={item.url} onInput={(t)=>{this.UpdateStateUpdateOrganicData('new_url', t.currentTarget.value); this.UpdateStateUpdateOrganicData('geo', item.geo);}} style={{minWidth:150}} className="form-control"></input></td>
                <td><button style={{color:'white'}} onClick={()=>{this.UpdateOrganic();}} className="btn btn-primary">Update</button></td>
                <td><button onClick={()=>{this.DeleteOrganic(item.geo);}}  className="btn btn-danger">Delete</button></td>
            </tr>
        )
    }

    RenderNaming = () => {
        if(!this.state.page_load){
            return(
                <div className='text-center'><Loading></Loading></div>
            )
        }
        else{
            return(
                <>
                    <div key={'naming'} className='row'>
                        <div className='col'>
                            <button onClick={()=>{this.setState({onelink_naming_modal:true})}} className={this.state.onelink_naming.activ?'btn btn-outline-success form-control m-1':'btn btn-outline-danger form-control m-1'}>Onelink naming</button>
                        </div>
                        <div className='col'>
                            <button onClick={()=>{this.setState({reserve_onelink_naming_modal:true})}} className={this.state.reserve_onelink_naming.activ?'btn btn-outline-success form-control m-1':'btn btn-outline-danger form-control m-1'}>Reserv Onelink naming</button>
                        </div>
                        <div className='col'>
                            <button onClick={()=>{this.setState({add_naming_modal:true})}} className='btn btn-primary form-control m-1'>Add naming</button>
                        </div>
                    </div>
                    <br></br>
                    <div className='table-responsive' style={{padding:2}}>
                        <table className="table table-striped table-bordered text-center">
                            <thead>
                                <tr>
                                    <th>Naming</th>
                                    <th>Url</th>
                                    <th>Update</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.namings_data.map((item:any) => this.NamingTableItem(item))}
                            </tbody>
                        </table>
                    </div>
                </>
            )
        }
    }
    NamingTableItem = (item:any) => {
        return(
            <tr key={item.geo}>
                <td><input defaultValue={item.naming}  disabled={true} className="form-control"></input></td>
                <td><input defaultValue={item.url} onInput={(t)=>{this.UpdateStateUpdateNamingData('new_url', t.currentTarget.value); this.UpdateStateUpdateNamingData('naming', item.naming);}} style={{minWidth:150}} className="form-control"></input></td>
                <td><button style={{color:'white'}} onClick={()=>{this.UpdateNaming();}} className="btn btn-primary">Update</button></td>
                <td><button onClick={()=>{this.DeleteNaming(item.naming);}}  className="btn btn-danger">Delete</button></td>
            </tr>
        )
    }

    RenderAccounts = () => {
        if(!this.state.page_load){
            return(
                <div className='text-center'><Loading></Loading></div>
            )
        }
        else{
            return(
                <div key={'accounts'} className='form-group'>
                    <label>FB login</label>
                    <input value={this.state.accounts_data.fb_login} onInput={(t)=>{let ad=this.state.accounts_data; ad.fb_login=t.currentTarget.value; this.setState({accounts_data:ad})}} className='form-control'></input>
                    <label>FB pass</label>
                    <input value={this.state.accounts_data.fb_pass} onInput={(t)=>{let ad=this.state.accounts_data; ad.fb_pass=t.currentTarget.value; this.setState({accounts_data:ad})}} className='form-control'></input>
                    <label>FB app id</label>
                    <input value={this.state.accounts_data.fb_app_id} onInput={(t)=>{let ad=this.state.accounts_data; ad.fb_app_id=t.currentTarget.value; this.setState({accounts_data:ad})}} className='form-control'></input>
                    <label>AppsFlyer login</label>
                    <input value={this.state.accounts_data.appsflyer_login} onInput={(t)=>{let ad=this.state.accounts_data; ad.appsflyer_login=t.currentTarget.value; this.setState({accounts_data:ad})}} className='form-control'></input>
                    <label>AppsFlyer pass</label>
                    <input value={this.state.accounts_data.appsflyer_pass} onInput={(t)=>{let ad=this.state.accounts_data; ad.appsflyer_pass=t.currentTarget.value; this.setState({accounts_data:ad})}} className='form-control'></input>
                    <label>Octo profile id</label>
                    <input value={this.state.accounts_data.octo_profile_id} onInput={(t)=>{let ad=this.state.accounts_data; ad.octo_profile_id=t.currentTarget.value; this.setState({accounts_data:ad})}} className='form-control'></input>
                    <label>Appsflyer jwt</label>
                    <input value={this.state.accounts_data.appsflyer_jwt} onInput={(t)=>{let ad=this.state.accounts_data; ad.appsflyer_jwt=t.currentTarget.value; this.setState({accounts_data:ad})}} className='form-control'></input>
                    <label>Octo number (with gmail)</label>
                    <input value={this.state.accounts_data.octo_number} onInput={(t)=>{let ad=this.state.accounts_data; ad.octo_number=t.currentTarget.value; this.setState({accounts_data:ad})}} className='form-control'></input>
                    <hr></hr>
                    <button onClick={()=>{this.UpdateAccounts();}} className='btn btn-primary form-control'>Update</button>
                </div>
            )
        }
    }

    RenderNotifications = () => {
        if(!this.state.page_load){
            return(
                <div className='text-center'><Loading></Loading></div>
            )
        }
        else{
            return(
                <div key={'noti'}>
                    <div className="alert alert-danger">
                            <strong>At the moment push service is not supported!</strong>
                        </div>
                    <div className="card">
                        <div className="card-header c_p" style={{borderBottom:'none'}} onClick={()=>{this.setState({noti_conn_sett_show:this.state.noti_conn_sett_show})}}>
                            <a className="card-link" data-toggle="collapse" >
                                <strong>Сonnection settings</strong>
                            </a>
                        </div>
                        <div  className={this.state.noti_conn_sett_show?'collapse show':'collapse'}>
                            <div className="card-body">
                                {this.state.noti_connect_data.success?null:<div className="alert alert-danger"><strong>No connect to push service, set fcm server key and upload fcm json file and click connect!</strong></div>}
                                <div className="form-group">
                                    <label>app package</label>
                                    <input defaultValue={this.state.app_data.package} className="form-control" disabled={true}></input>
                                    <label>fcm server key</label>
                                    <input value={this.state.noti_connect_data.data.fcm_server_key} onInput={(t)=>{this.UpdateStateNotiConnectData('fcm_server_key', t.currentTarget.value)}} className="form-control"></input>
                                    <br></br>
                                    <FileUploader
                                        style={{width:'100%'}}
                                        multiple={false}
                                        handleChange={(t:any)=>{let reader = new FileReader();reader.readAsText(t);reader.onload = (e:any)=>{ this.UpdateStateNotiConnectData('fcm_json', JSON.parse(e.target.result))}}}
                                        name="file"
                                        types={["JSON"]}
                                    />
                                    <br></br>
                                    {this.state.noti_connect_data.success?<button disabled={this.state.btn_loader} onClick={()=>{this.UpdateNotiAppData()}} className='btn btn-primary form-control'> {this.state.btn_loader?spinner():null} Update</button>:<button disabled={this.state.btn_loader} onClick={()=>{this.ConnectAppToPushService()}} className='btn btn-primary form-control'>{this.state.btn_loader?spinner():null} Connect</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header c_p" style={{borderBottom:'none'}} onClick={()=>{this.setState({noti_send_noti_show:this.state.noti_send_noti_show})}}>
                            <a className="card-link" data-toggle="collapse" >
                                <strong>Send notification</strong>
                            </a>
                        </div>
                        <div  className={this.state.noti_send_noti_show?"collapse show":"collapse"}>
                            <div className="card-body">
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col'>
                                            <label>Naming (empty naming = organic)</label>
                                            <input value={this.state.noti_send_data.naming} onInput={(t)=>{this.UpdateStateNotiSendData('naming', t.currentTarget.value)}} className="form-control" ></input>
                                        </div>
                                        <div className='col'>
                                            <label>Event</label>
                                            <Select value={this.state.noti_send_data.event} onChange={(t)=>{this.UpdateStateNotiSendData('event', t)}} options={[{label:'installation', value:'installation'}, {label:'registration', value:'registration'}, {label:'deposit', value:'deposit'}]}></Select>
                                        </div>
                                        <div className='col'>
                                            <label>Geo</label>
                                            <GeoListSelect onChange={(t:any)=>{this.UpdateStateNotiSendData('geo', [t.value])}}></GeoListSelect>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>push title</label>
                                        <input value={this.state.noti_send_data.title} onInput={(t)=>{this.UpdateStateNotiSendData('title', t.currentTarget.value)}} className="form-control"></input>
                                        <label>push body</label>
                                        <textarea value={this.state.noti_send_data.body} onInput={(t)=>{this.UpdateStateNotiSendData('body', t.currentTarget.value)}} className="form-control"></textarea>
                                        <label>push big icon</label>
                                        <input value={this.state.noti_send_data.icon} onInput={(t)=>{this.UpdateStateNotiSendData('icon', t.currentTarget.value)}} className="form-control"></input>
                                        <hr></hr>
                                        <button disabled={this.state.btn_loader} onClick={()=>{this.SendNoti()}} className='btn btn-primary form-control'>{this.state.btn_loader?spinner():null} Send notification</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header c_p" style={{borderBottom:'none'}} onClick={()=>{this.setState({noti_lastes_noti_show:this.state.noti_lastes_noti_show})}}>
                            <a className="card-link" data-toggle="collapse" >
                                <strong>Latest notifications</strong>
                            </a>
                        </div>
                        <div  className={this.state.noti_lastes_noti_show?"collapse show":"collapse"}>
                            <div className="card-body">
                            {this.state.noti_loader_lastes_data?spinner():<span><i onClick={()=>{this.GetNotificationsData()}} style={{fontSize:18, zIndex:999}} className="material-icons refresh text-primary c_p" ></i></span>}
                                <div className='table-responsive'>
                                    <table  className="table table-hover table-bordered text-center" >
                                        <thead>
                                            <tr>
                                                <th>date</th>
                                                <th>total</th>
                                                <th>success</th>
                                                <th>fail</th>
                                                <th>clicks</th>
                                                <th>naming</th>
                                                <th>geo</th>
                                                <th>title</th>
                                                <th>body</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.noti_lastes_data.map((item:any) => {
                                                return(
                                                    <tr key={item.date_create}>
                                                        <td>{item.date_create}</td>
                                                        <td>{item.total_sender}</td>
                                                        <td>{item.success_sender}</td>
                                                        <td>{item.failure_sender}</td>
                                                        <td>{item.clicks}</td>
                                                        <td>{item.naming}</td>
                                                        <td>{item.geo}</td>
                                                        <td>{item.title}</td>
                                                        <td>{item.body}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    RenderTesting = () => {
        return(
            <div className="tab-pane active p-3" id="screen-5" role="tabpanel">
                <hr></hr>
                <div>
                    {this.RenderTestingList()}
                    
                    <hr></hr>
                    {this.RenderTestingForm()}
                </div>
                
            </div>
        )
    }

    RenderHome = () => {
        if(!this.state.page_load){
            return(
                <div className='text-center'><Loading></Loading></div>
            )
        }
        else{
            let statusClass = '';
            switch(this.state.app_data.status){
                case 'public':
                    statusClass = "card alert-success";
                    break;
                case 'develop':
                    statusClass = "card alert-primary";
                    break;
                case 'moderation':
                    statusClass = "card alert-warning";
                    break; 
                case 'ban':
                    statusClass = "card alert-danger";
                    break;   
            }
            return(
                <>
                    <div key={'home'} className='row'>
                        <div className='col'>
                            <div className='text-center'>
                                <label>status</label>
                                <div className={statusClass} style={{padding:10}}><strong>{this.state.app_data.status}</strong></div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='text-center'>
                                <label>activ</label>
                                <div className="card  alert-success" style={{padding:10}}><strong>{String(this.state.app_data.activ)}</strong></div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='text-center'>
                                <label>installs</label>
                                <div className="card  alert-primary" style={{padding:10}}><strong>{this.state.app_data.count_installs}</strong></div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center' style={{marginTop:-10}}>
                        <a href={'/install_logs/'+this.state.app_data.package+'/'}  style={{borderRadius:'10rem', marginRight:2}} className="badge bg-primary ">install logs</a>
                        <a href={'/test_request/'+this.state.app_data.package+'/'} style={{borderRadius:'10rem'}} className="badge bg-primary ">test request</a>
                    </div>
                    <hr></hr>
                    <div className='row'>
                        <div className='col text-center' >
                            <span style={{borderRadius:'10rem', marginRight:2}} className="badge bg-primary ">create:{convertUTCTimeToLocalTime(this.state.app_data.create_time)}</span>
                        </div>
                        <div className='col text-center'>
                            <span style={{borderRadius:'10rem', marginRight:2}} className="badge bg-success ">public:{convertUTCTimeToLocalTime(this.state.app_data.public_time)}</span>
                        </div>
                        <div className='col text-center'>
                            <span style={{borderRadius:'10rem', marginRight:2}} className="badge bg-warning ">moderation:{convertUTCTimeToLocalTime(this.state.app_data.moderation_time)}</span>
                        </div>
                        <div className='col text-center'>
                            <span style={{borderRadius:'10rem', marginRight:2}} className="badge bg-danger ">ban:{convertUTCTimeToLocalTime(this.state.app_data.ban_time)}</span>
                        </div>
                    </div>
                    <br></br>
                    <div className='list-groups' style={{borderRadius:'.65rem'}}>
                        <span className="list-group-item list-group-item-action">App url: <a target="_blank" href={this.GetAppStoreUrl()}>{this.GetAppStoreUrl()}</a></span>
                        <span className="list-group-item list-group-item-action">Postback url (reg, dep): {'https://crewpost.appsserviceswin.site/?reg={reg}&dep={dep}&hash={hash}&app='+url[1]}</span>
                        <span className="list-group-item list-group-item-action">ID: {this.state.app_data.id}</span>
                    </div>
                    <hr></hr>
                    <div className='form-group'>
                        <label>Name</label>
                        <input value={this.state.app_data.name} onInput={(t)=>{this.UpdateStateAppData('name', t.currentTarget.value)}} className='form-control'></input>
                        <label>Package</label>
                        <input value={this.state.app_data.package} onInput={(t)=>{this.UpdateStateAppData('package', t.currentTarget.value)}} className='form-control'></input>
                        <label>Comment</label>
                        <textarea value={this.state.app_data.comment} onInput={(t)=>{this.UpdateStateAppData('comment', t.currentTarget.value)}} className='form-control'></textarea>
                        <label>Host</label>
                        <input value={this.state.app_data.host} onInput={(t)=>{this.UpdateStateAppData('host', t.currentTarget.value)}} className='form-control'></input>
                        <label>AppsFlyer key</label>
                        <input value={this.state.app_data.appsflyer_key} onInput={(t)=>{this.UpdateStateAppData('appsflyer_key', t.currentTarget.value)}} className='form-control'></input>
                        <label>Onesignal app id</label>
                        <input value={this.state.app_data.onesignal_app_id} onInput={(t)=>{this.UpdateStateAppData('onesignal_app_id', t.currentTarget.value)}} className='form-control'></input>
                        <label>Onesignal api key</label>
                        <input value={this.state.app_data.onesignal_api_key} onInput={(t)=>{this.UpdateStateAppData('onesignal_api_key', t.currentTarget.value)}} className='form-control'></input>
                        <label>Status</label>
                        <AppStatusSelect value={{label:this.state.app_data.status, value:this.state.app_data.status}} onChange={(t:any)=>{this.UpdateStateAppData('status', t.value)}}></AppStatusSelect>
                        <label>Activ</label>
                        <Select onChange={(t:any)=>{this.UpdateStateAppData('activ', t.value)}} value={{label:String(this.state.app_data.activ), value:this.state.app_data.activ}} options={s_options}></Select>
                        <label>Developer</label>
                        <DevelopersSelect value={this.state.app_data.developer} onChange={(t:any)=>{this.UpdateStateAppData('developer', t.value)}}></DevelopersSelect>
                        <label>Group</label>
                        <AppGroupsSelect value={this.state.app_data.group} onChange={(t:any)=>{this.UpdateStateAppData('group', t.value)}}></AppGroupsSelect>
                        <label>Production</label>
                        <Select onChange={(t:any)=>{this.UpdateStateAppData('production', t.value)}} value={{label:String(this.state.app_data.production), value:this.state.app_data.production}} options={s_options}></Select>
                        <label>Production type</label>
                        <Select value={{label:this.state.app_data.production_type, value:this.state.app_data.production_type}} options={[{label:'rent', value:'rent'}, {label:'sale', value:'sale'}, {label:'sale and rent', value:'sale and rent'}]} onChange={(t:any)=>{this.UpdateStateAppData('production_type', t.value)}}></Select>
                    </div>
                    <hr></hr>
                    <button onClick={()=>{this.UpdateAppData()}} disabled={this.state.btn_loader} className='btn btn-primary form-control'>{this.state.btn_loader?spinner():null} Edit</button>
                    <hr></hr>
                    {(getUserData('roles').indexOf('admin') != -1)?<button onClick={()=>{this.setState({delete_modal:true})}} className='btn btn-danger form-control'>Delete</button>:null}
                </>
            )
        }
    }
  
    render() {
      return (
        <div className='page-content'>
            <div style={{borderRadius:'.65rem', borderTopRightRadius:'.65rem', borderTopLeftRadius:'.65rem', marginTop:-1, background:'rgba(108, 117, 125, 1)'}}>
                <div className='row' style={{color:'white'}}>
                    {this.state.page_menu.map((item:any) => {
                        return(
                            <div key={item.name} className='col'>
                                <div onClick={()=>{this.SetPageMenu(item.name)}} style={{borderRadius:'.65rem', padding:7, display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}} className={(item.activ)?'bg-primary text-center c_p':'text-center c_p'}>
                                    <div style={{marginRight:1}}>{item.icon}</div>
                                    <div><span className='text-white'> {item.name}</span></div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="card" style={{padding:0, borderWidth:0.01, marginTop:5}}>
                <div className='card-body'>
                    {this.state.page_menu.map((item:any) => {
                        if(item.activ){
                            if(item.name == 'Home'){
                                return this.RenderHome();
                            }
                            if(item.name == 'rganic'){
                                return this.RenderOrganic();
                            }
                            if(item.name == 'Naming'){
                                return this.RenderNaming();
                            }
                            if(item.name == 'Accounts'){
                                return this.RenderAccounts();
                            }
                            if(item.name == 'Notifications'){
                                return this.RenderNotifications();
                            }
                            if(item.name == 'Testing'){
                                return this.RenderTesting();
                            }
                        }
                    })}
                </div>
          </div>
          <CustomModal visible={this.state.delete_modal} header={'Attention!'} callback={()=>{this.setState({delete_modal:false})}}>
            <div className='text-center'>
              <h4>Confirm the deletion request!</h4>
            </div>
            <label>Write secret password</label>
            <input value={this.state.delete_modal_secret_password} onInput={(t)=>{this.setState({delete_modal_secret_password:t.currentTarget.value})}} className='form-control'></input>
            <br></br>
            <button onClick={()=>{this.Delete()}} className='btn btn-outline-danger form-control'>Confirm delete</button>
          </CustomModal>
          <CustomModal visible={this.state.add_organic_modal} header={'Add organic'} callback={()=>{this.setState({add_organic_modal:false})}}>
            <label>Select geo</label>
            <GeoListSelect onChange={(t:any)=>{this.UpdateStateAddOrganicData('geo', t.value)}}></GeoListSelect>
            <label>Url</label>
            <input value={this.state.add_organic_data.url} onInput={(t)=>{this.UpdateStateAddOrganicData('url', t.currentTarget.value)}} className='form-control'></input>
            <br></br>
            <button onClick={()=>{this.AddOrganic()}} className='btn btn-outline-primary form-control'>Add geo</button>
          </CustomModal>
          <CustomModal visible={this.state.onelink_organic_modal} header={'Onelink organic'} callback={()=>{this.setState({onelink_organic_modal:false})}}>
            <label>Activ</label>
            <Select value={{label:String(this.state.onelink_organic.activ), value:this.state.onelink_organic.activ}} onChange={(t:any)=>{let oon=this.state.onelink_organic; oon.activ=t.value;this.setState({onelink_organic:oon})}} options={s_options}></Select>
            <label>Url</label>
            <input value={this.state.onelink_organic.url} onInput={(t)=>{let oon=this.state.onelink_organic; oon.url=t.currentTarget.value;this.setState({onelink_organic:oon})}} className='form-control'></input>
            <br></br>
            <button onClick={()=>{this.UpdateOnelinkOrganic()}} className='btn btn-outline-primary form-control'>Update</button>
          </CustomModal>
          <CustomModal visible={this.state.add_naming_modal} header={'Add naming'} callback={()=>{this.setState({add_naming_modal:false})}}>
            <label>Naming</label>
            <input value={this.state.add_naming_data.naming} onInput={(t)=>{this.UpdateStateAddNamingData('naming', t.currentTarget.value)}} className='form-control'></input>
            <label>Url</label>
            <input value={this.state.add_naming_data.url} onInput={(t)=>{this.UpdateStateAddNamingData('url', t.currentTarget.value)}} className='form-control'></input>
            <br></br>
            <button onClick={()=>{this.AddNaming();}} className='btn btn-outline-primary form-control'>Add naming</button>
          </CustomModal>
          <CustomModal visible={this.state.onelink_naming_modal} header={'Onelink naming'} callback={()=>{this.setState({onelink_naming_modal:false})}}>
            <label>Activ</label>
            <Select value={{label:String(this.state.onelink_naming.activ), value:this.state.onelink_naming.activ}} onChange={(t:any)=>{let oon=this.state.onelink_naming; oon.activ=t.value;this.setState({onelink_naming:oon})}} options={s_options}></Select>
            <label>Url</label>
            <input value={this.state.onelink_naming.url} onInput={(t)=>{let oon=this.state.onelink_naming; oon.url=t.currentTarget.value;this.setState({onelink_naming:oon})}} className='form-control'></input>
            <br></br>
            <button onClick={()=>{this.UpdateOnelinkNaming();}} className='btn btn-outline-primary form-control'>Update</button>
          </CustomModal>
          <CustomModal visible={this.state.reserve_onelink_naming_modal} header={'Reserve Onelink naming'} callback={()=>{this.setState({reserve_onelink_naming_modal:false})}}>
            <label>Activ</label>
            <Select value={{label:String(this.state.reserve_onelink_naming.activ), value:this.state.reserve_onelink_naming.activ}} onChange={(t:any)=>{let oon=this.state.reserve_onelink_naming; oon.activ=t.value;this.setState({reserve_onelink_naming:oon})}} options={s_options}></Select>
            <label>Url</label>
            <input value={this.state.reserve_onelink_naming.url} onInput={(t)=>{let oon=this.state.reserve_onelink_naming; oon.url=t.currentTarget.value;this.setState({reserve_onelink_naming:oon})}} className='form-control'></input>
            <br></br>
            <button onClick={()=>{this.UpdateReserveOnelinkNaming();}} className='btn btn-outline-primary form-control'>Update</button>
          </CustomModal>
        </div>
      );
    }
}

