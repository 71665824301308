import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, urlNavigate, getUrlPath, spinner } from '../components/function';
import Loading from '../pages/Loading';
import CustomModal from '../components/CustomModal';

export default class QuantityInstallAnalyticsPage extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            list_table:[],
            total_installs:0,
            page_load:false,
            from:'',
            to:''
        }
    }
  
    componentDidMount(){
        this.GetData();
    }

    GetData = () => {
        this.setState({
            page_load:false
        })
        ApiReq('/quantity_install_analytics/', {from:this.state.from, to:this.state.to}).then(res => {
            if(res != false){
                if(res.success == true){
                    this.setState({
                        list_table:res.data,
                        page_load:true
                    })
                    setTimeout(()=>{
                        this.GetTotal();
                    }, 500)
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                }
            }
        })
    }

    GetTotal = () => {
        let total_installs:number = 0;
        let list_table = this.state.list_table;
        for(let i=0; i<list_table.length; i++){
            total_installs = total_installs + Number(list_table[i].installs);
        }
        this.setState({
            total_installs:total_installs
        })
    }
  
    render() {
        return (
            <div className='page-content'>
                <div className="card">
                    <div className='card-body'>
                        {/* <div style={{textAlign:'right'}}>
                            <button onClick={()=>{this.setState({modal:true})}} className="btn btn-primary"><i className="material-icons control_point c_p" style={{fontSize:16, margin:4}}></i>Add geo</button>
                        </div> */}
                        <div className='row'>
                            <div className='col'>
                                <label>From</label>
                                <input onInput={(t)=>{this.setState({from:t.currentTarget.value})}} className='form-control' type='datetime-local'></input>
                            </div>
                            <div className='col'>
                                <label>To</label>
                                <input onInput={(t)=>{this.setState({to:t.currentTarget.value})}} className='form-control' type='datetime-local'></input>
                            </div>
                            <div className='col'>
                                <label>Get report</label>
                                <button onClick={()=>{this.GetData()}} disabled={!this.state.page_load} className='btn btn-primary form-control'>{this.state.page_load?null:spinner()} Get report</button>
                            </div>
                        </div>
                        <hr></hr>
                        {
                            !this.state.page_load ? 
                                <div className='text-center'><Loading></Loading></div>
                            :
                            <div>
                                <div className='text-center'>
                                    <h1>Total {this.state.total_installs}</h1>
                                </div>
                                <div className='table-responsive' style={{padding:2}}>
                                    <table className='table table-hover table-bordered text-center'>
                                        <thead>
                                            <tr>
                                                <th>App</th>
                                                <th>Platform</th>
                                                <th>Installs</th>
                                                <th>Show</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.list_table.map((item:any) => {
                                                return (
                                                    <tr key={item.app_id}>
                                                        <td>{item.app_name}</td>
                                                        <td>{item.app_platform}</td>
                                                        <td>{item.installs}</td>
                                                        <td><a target='_blank' href={'/app/'+item.app_package+'/'} ><i className="material-icons arrow_circle_up text-primary" style={{rotate:'90deg',  fontSize:26}}></i></a></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        
                    </div>
                </div>
            </div>
        )
    }
}

