import React, { Component } from 'react';


export default class Loading extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
      }
    }
  
    componentDidMount(){
      
    }
  
    render() {
      if(this.props.center == true){
        return (
          <div style={{}}>
              <div style={{width:'100%', height:'100%', textAlign:'center', marginTop:'15%'}}>
                <div className="spinner-border text-primary"></div>
              </div>
          </div>
        );
      }
      else{
        if(this.props.small == true){
          return(
            <div className="spinner-border spinner-border-sm text-primary"></div>
          )
        }
        else{
          return (
            <div className="spinner-border text-primary"></div>
          );
        }
      }
    }
}

