import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate } from './function';
import Select from 'react-select'


export default class AppGroupsSelect extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        select_data:[
            
        ],
        value:null
      }
    }
  
    componentDidMount(){
        ApiReq('/app_groups/', {event:'get'}).then(res => {
            if(res != false){
                if(res.success == true){
                    let select_data = [];
                    let value = null;
                    for(let i=0; i<res.data.length; i++){
                        select_data.push({ value: res.data[i].id, label: res.data[i].name })
                        if(this.props.value != null){
                          if(res.data[i].name == this.props.value || res.data[i].id == this.props.value){
                            value = { value: res.data[i].id, label: res.data[i].name };
                          }
                        }
                    }
                    this.setState({
                        select_data:select_data,
                        value:value
                    })
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                }
            }
        })
    }

    Change = (res:any) => {
        if(this.props.onChange != null){
            this.props.onChange(res);
        }
        this.setState({
          value:res
        })
    }
  
  
    render() {
      return(
        <Select value={this.state.value} onChange={(res)=>{this.Change(res)}}  options={this.state.select_data} />
      )
    }
}

