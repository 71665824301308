import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { UserAgentProvider, useUserAgent } from 'react-ua';
import CryptoJS from "crypto-js";
import * as moment from 'moment-timezone';

export function getUrlPath() {
  let url: any = window.location.pathname;
  url = url.split("/");
  url = url.filter(function (el: any) {
    return (el != null && el != "") || el === 0;
  });

  return url;
}

export function urlNavigate(url: any) {
  window.location.replace(url);
}

export function getApiDomain() {
  return getApiDomainBase() + "api";
}

export function getApiDomainBase() {
  // return "http://localhost:8080/";
  // return 'http://win-aa-back:8888/';
  return "https://api.workhardplayeasy.pro/";
}

export function getCheckerDomain() {
  return "";
}

export function gk(p: string) {
  let dd = CryptoJS.AES.decrypt(
    "U2FsdGVkX1+WlKU3wYEjzzL/yalCA1CU3QWOtVPUflIWLuvbuBeVm0t6Vn8MmMaouKbjnVOcngX0tWUyOCJ4fzc/8WU3lRFjBfb+mqK7xg2h+q+9hUOX5FPCYa/1UDFhXFu2CTp/iUKL1d42QpJ6Gjp2JCwgiwFZpIGuS0gaMgJfyd65JNjkqVqGj0boRwPcT92dUFBUgPqvehOrxqP/gD9ib6srfauKEb/AYQ6Wkde1SQe7+wVE8J9Ilf+Mf33d",
    "123456"
  ).toString(CryptoJS.enc.Utf8);
  let k = JSON.parse(dd);
  return k[p];
}

export function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function encryptAES(message: any) {
  let key = CryptoJS.enc.Hex.parse(gk("h1"));
  let iv = CryptoJS.enc.Hex.parse(gk("h2"));
  let encrypted = CryptoJS.AES.encrypt(message, key, {
    iv: iv,
    padding: CryptoJS.pad.ZeroPadding,
  }).toString();
  return encrypted;
}

export function clientEncryptAES(message: any) {
  return CryptoJS.AES.encrypt(message, gk("cea")).toString();
}

export function clientDecryptAES(message: any) {
  let bytes = CryptoJS.AES.decrypt(message, gk("cea"));
  return bytes.toString(CryptoJS.enc.Utf8);
}

export function notySend(type: any = null, text: any) {
  let toastOption: any = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
    newestOnTop: true,
  };

  switch (type) {
    case "success":
      toast.success(text, toastOption);
      break;
    case "error":
      toast.error(text, toastOption);
      break;
    case "warning":
      toast.warning(text, toastOption);
      break;
    default:
      toast.error(text, toastOption);
  }
}

export function parsGetParams(url: any) {
  if (url.indexOf("?") != -1) {
    let params = url.split("?");
    if (params[1].indexOf("&") != -1) {
      let arr_params = params[1].split("&");
      let retern_arr_params: any = {};
      for (let i = 0; i < arr_params.length; i++) {
        let temp = arr_params.split("=");
        retern_arr_params[temp[0]] = temp[1];
      }
      return retern_arr_params;
    } else {
      let temp = params[1].split("=");
      let retern_arr_params: any = {};
      retern_arr_params[temp[0]] = temp[1];
      return retern_arr_params;
    }

    return params;
  } else {
    return false;
  }
}

export function ApiReq(url: any = null, body: any = null) {
  let check_dop_req = true;
  let apidomain = getApiDomain();
  let headers = {
    "Content-Type": "application/json",
    "Auth-Token": getApiToken(),
  };
  let reqParam: any = {
    method: "GET",
    headers: headers,
  };
  if (body != null) {
    reqParam.method = "POST";
    reqParam.body = JSON.stringify(body);
  }

  return fetch(apidomain + url, reqParam)
    .then((response) => {
      // console.log(url)
      if (response.status == 200) {
        return response.json().then((res) => {
          if (res.error != null) {
            if (res.error.indexOf("authorization not found") !== -1) {
              urlNavigate("/login/");
            }
          }
          return res;
        });
      } else {
        return DopApiReq(apidomain + url, reqParam);
      }
    })
    .catch((error) => {
      notySend("error", error + "\nurl:" + apidomain + url);
      return false;
    });
}

export function DopApiReq(url: string, req_param: any) {
  return fetch(url, req_param)
    .then((response) => {
      if (response.status == 200) {
        return response.json().then((res) => {
          return res;
        });
      } else {
        return false;
      }
    })
    .catch((error) => {
      //notySend('error', error+'\nurl:'+url);
      return false;
    });
}

export function getApiToken() {
  return getUserData("authToken");
}

export function searchItem(items: any, searchData: any) {
  let new_items: any = [];
  for (let i = 0; i < items.length; i++) {
    Object.keys(items[i]).forEach((key) => {
      Object.keys(searchData).forEach((key_s) => {
        if (key == key_s) {
          if (searchData[key_s] != "") {
            if (items[i][key].indexOf(searchData[key_s]) != -1) {
              new_items.push(items[i]);
            }
          } else {
            // new_items.push(items[i]);
          }
        }
        // console.log(key_s, searchData[key_s]);
      });
      // console.log(key, items[i][key]);
    });
  }
  return new_items;
}

export function parseUAgent(userAgent: string) {
  if (userAgent != "") {
    // return useUserAgent(userAgent);
  } else {
    // return useUserAgent();
  }
}

export function sync_config() {
  ApiReq("/system_config/get_all_config/").then((res) => {
    if (res != false) {
      if (res.success == true) {
        // console.log(res)
        let sync_config: any = {};
        for (let i = 0; i < res.data.length; i++) {
          sync_config[res.data[i].name] = res.data[i].val;
        }
        localStorage.setItem("pixel_crm_config", JSON.stringify(sync_config));
      } else {
        notySend("error", "error #" + res.error + "\n" + res.info);
      }
    }
  });
}

export function getClientConfig(param: string = "") {
  if (param == "logo" || param == "version") {
    if (param == "logo") {
      let domain = getApiDomainBase();
      return domain + "/uploads/img/logo.png";
    }
    if (param == "version") {
      return "0.6.1";
    }
  } else {
  }
}

export function isJson(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

///////neeeew//////////////////////////////////////////////////
export function getUserData(param: string = "") {
  let startUserData = {
    login: false,
    loginName: null,
    loginUnix: null,
    roles: null,
    team: "",
  };
  let userData: any = localStorage.getItem(window.location.host + "-us");
  if (userData != null) {
    userData = JSON.parse(userData);
  } else {
    userData = startUserData;
    //localStorage.setItem(window.location.host+'-us', JSON.stringify(startUserData))
  }

  if (param != "") {
    return userData[param];
  } else {
    return userData;
  }
}

export function setUserData(
  login: boolean,
  login_name: string,
  loginUnix: number,
  roles: object,
  authToken: string,
  team: string
) {
  let UserData = {
    login: login,
    loginName: login_name,
    loginUnix: loginUnix,
    roles: roles,
    authToken: authToken,
    team: team,
  };
  localStorage.setItem(window.location.host + "-us", JSON.stringify(UserData));
}

export function checkSmartRout(c_url: string, c_rout: string) {
  let ra: any = c_rout.split("/");
  ra = ra.filter(function (el: any) {
    return el != "";
  });
  let rp: any = [];
  let temp: any;
  for (let i = 0; i < ra.length; i++) {
    if (ra[i].indexOf("(") != -1) {
      temp = ra[i];
      temp = temp.replace("(", "");
      temp = temp.replace(")", "");
      if (temp.indexOf("[") != -1) {
        temp = temp.replace("[", "");
        temp = temp.replace("]", "");
        temp = temp = temp.split(",");
        rp.push({ type: typeof temp, value: temp });
      } else {
        rp.push({ type: typeof ra[i], value: temp });
      }
    } else {
      rp.push({ type: typeof ra[i], value: ra[i] });
    }
  }

  let ua = c_url.split("/");
  ua = ua.filter(function (el) {
    return el != "";
  });
  let check = true;
  if (ua.length == ra.length) {
    for (let i = 0; i < ua.length; i++) {
      if (rp[i].type == "string") {
        if (rp[i].value !== "*") {
          if (ua[i] !== rp[i].value) {
            check = false;
            break;
          }
        }
      } else {
        if (rp[i].value.indexOf(ua[i]) == -1) {
          check = false;
          break;
        }
      }
    }
  } else {
    check = false;
  }

  return check;
}

export function spinner() {
  return <div className="spinner-border spinner-border-sm"></div>;
}

export function ifIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function getJsonFromUrl(url:string) {
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    const params:any = {};
    let match;
    while ((match = regex.exec(url))) {
        params[match[1]] = decodeURIComponent(match[2]);
    }
    return params;
}

export function getRandomString(length:number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export function convertUTCTimeToLocalTime(utcString: string): string {
    return moment.utc(utcString).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss')
}