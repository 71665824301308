import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { getClientConfig, getUserData, getUrlPath } from '../components/function';

let url = getUrlPath();

export default class Navigation extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
          page:null,
          menu:[
            {
                name:'Home', 
                icon:<i className="material-icons home">&#xe88a;</i>, 
                href:'/home/', 
                role:[], 
                active:false
            },
            {
                name:'Analytics', 
                icon:<i className="material-icons bar_chart">&#xe26b;</i>,  
                role:[], 
                active:false, 
                multi_list:[
                    {name:'Analytics public', href:'/analytics_public/', role:['admin']},
                    {name:'Analytics conversion', href:'/analytics_conversion/', role:['admin']},
                    {name:'Analytics by developer', href:'/analytics_developer/', role:[]},
                    {name:'Analytics by devices', href:'/analytics_devices/', role:['admin']},
                    {name:'Quantity Install Analytics', href:'/quantity_install_analytics/', role:['admin']}
                ]
            },
            {
                name:'Apps', 
                icon:<i className="material-icons widgets">&#xe1bd;</i>, 
                role:[],  
                active:false, 
                multi_list:[
                    {name:'Android', href:'/apps/android/public/', role:[]},
                    {name:'Ios', href:'/apps/ios/public/', role:[]}
                ]
            },
            {
                name:'Lists', 
                icon:<i className="material-icons subject">&#xe8d2;</i>,  
                role:['admin'], 
                active:false, 
                multi_list:[
                    {name:'Geo', href:'/list_geo/', role:[]},
                    {name:'Black list ip', href:'/black_list_ip/', role:[]},
                    {name:'Black list asn', href:'/black_list_asn/', role:[]},
                    {name:'App groups', href:'/app_groups/', role:[]}
                ]
            },
            // {
            //     name:'App domains', 
            //     icon:<i className="material-icons settings_input_component">&#xe8c0;</i>, 
            //     href:'/app_domains/', 
            //     role:['admin'], 
            //     active:false
            // },
            {
                name:'Sipc', 
                icon:<i className="material-icons cloud_sync">&#xeb5a;</i>, 
                href:'/sipc/', 
                role:[],  
                active:false
            },
            {
                name:'Service connect', 
                icon:<i className="material-icons hub">&#xe9f4;</i>, 
                href:'/service_connect/', 
                role:['admin'],  
                active:false
            },
            {
                name:'Api keys', 
                icon: <i className="material-icons vpn_key">&#xe0da;</i>, 
                href:'/api_keys/', 
                role:['admin'], 
                active:false
            },
            {
                name:'Transfer events', 
                icon:<i className="material-icons open_in_new">&#xe89e;</i>, 
                href:'/transfer_events/', 
                role:['admin'], 
                active:false, 
            },
            {
                name:'Integration', 
                icon:<i className="material-icons menu_book">&#xea19;</i>, 
                href:'/integration/', 
                role:[], 
                active:false, 
            },
            {
                name:'Users', 
                icon:<i className="material-icons group">&#xe7ef;</i>, 
                href:'/users/', 
                role:['admin'], 
                active:false, 
            },
        ]
      }
    }
  
    componentDidMount(){
        let menu = this.state.menu;
        for(let i=0; i<menu.length; i++){
            if(menu[i].name.toLowerCase().indexOf(url[0]) != -1){
                menu[i].active = true;
                break;
            }
        }
        this.setState({menu:menu})
        if(document.getElementById('menu') != null){
            let menu:any = document.getElementById('menu');
            let menu_a = menu.getElementsByTagName('a');
            for(let i=0; i<menu_a.length; i++){
                if(window.location.href == menu_a[i].href){
                    menu.getElementsByTagName('a')[i].classList.add('manu_a_active');
                    break;
                }
            }
        }
        
    }
  
    SetCollapsNavItem = (name:string) => {
        let menu = this.state.menu;
        for(let i=0; i<menu.length; i++){
            if(menu[i].name == name){
                menu[i].active = menu[i].active?false:true;
            }
        }
        this.setState({menu: menu});
    }

  
    render() {
      return (
        <aside className="sidebar-wrapper" data-simplebar="true" style={{overflow:'scroll'}}>
            <div className="sidebar-header" style={{textAlign:'center'}}>
                <div>
                    <img src={getClientConfig('logo')} style={{width:'65%'}} alt="logo icon"></img>
                </div>
            </div>
            <ul className="metismenu" id="menu" >
                {this.state.menu.map((item:any) => {
                    if(item.role.length == 0 || (item.role.length > 0 && getUserData('roles').indexOf('admin') !== -1)){
                        if(item.multi_list != undefined){
                            return (
                                <li key={item.name}>
                                    <a  onClick={()=>{this.SetCollapsNavItem(item.name)}} className={item.active?'has-arrow text-white manu_a_active':'has-arrow text-white'}>
                                        <div className="parent-icon">{item.icon}</div>
                                        <div className="menu-title">{item.name}</div>
                                    </a>
                                    <ul style={{display:item.active?'block':'none'}}>
                                        {item.multi_list.map((sub_item:any) => {
                                            if(sub_item.role.length == 0 || (sub_item.role.length > 0 && getUserData('roles').indexOf('admin') !== -1)){
                                                return(
                                                    <li key={sub_item.name}> <a className='text-white' href={sub_item.href}><i className="fadeIn animated bx bx-radio-circle"></i>{sub_item.name}</a></li>
                                                )
                                            }
                                            else{
                                                return null;
                                            }
                                        })}
                                    </ul>
                                </li>
                            )
                        }
                        else{
                            return(
                                <li key={item.name}>
                                    <a className='text-white ' href={item.href}>
                                        <div className="parent-icon">{item.icon}</div>
                                        {/* {warn_icon} */}
                                        <div className="menu-title">{item.name}</div>
                                    </a>
                                </li>
                            )
                        }
                    }
                    else{
                        return null;
                    }
                })}
            </ul>
            <span className='text-white' style={{position:'absolute', top:'96%', left:10}}>v. {getClientConfig('version')}</span>
        </aside>
      );
    }
}



