import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

const Footer_page: React.FC = () => {
  return (
    <div>
        <div className="overlay nav-toggle-icon"></div>
        <a href="#" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a> 
    </div> 
  );
};

export default Footer_page;
