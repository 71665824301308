import React, { useState, Component } from 'react';
// import {NotificationContainer, NotificationManager} from 'react-notifications';
import Select from 'react-select';
import {getApiDomain} from '../components/function';
import { createFilter } from "react-select";
import { getUserData, ApiReq, notySend, urlNavigate } from '../components/function';

let url = window.location.pathname.split('/');
url = url.filter(element => element !== "");
  
let apps_list:any = [];

export default class AppsSelectList extends Component<any, any> {
  constructor(props: any){
    super(props);
    this.state = {
        apps_list:[
            { value: 0, label: 'null',  }
        ],
        start_apps_list:[],
        current_app:null
    }
  }

  componentDidMount(){
    this.GetAppsList();
    setTimeout(()=>{
      if(this.props.current != null){
          this.GetAppsList();
      }
  }, 1000)
  }

  GetAppsList = () => {
    let data:any = {select:['name', 'status', 'package'], where:[{team:getUserData('team')}]};
    if(this.props.platform != null){
      data = {select:['name', 'status', 'package'], where:[{platform:[this.props.platform]}, {team:getUserData('team')}]}
    }
    ApiReq('/apps/get_apps_data/', data).then(res => {
      if(res != false){
          if(res.success == true){
            let data = {data:res.data};
            apps_list = [];
            for(let i=0; i<data.data.length; i++){
              if(data.data[i].status == 'public'){
                apps_list.push({ value: data.data[i].package, label: <span className='text-success'>{data.data[i].name}</span>})
              }
            }
            for(let i=0; i<data.data.length; i++){
              if(data.data[i].status == 'moderation'){
                apps_list.push({ value: data.data[i].package, label: <span className='text-warning'>{data.data[i].name}</span>})
              }
            }
            for(let i=0; i<data.data.length; i++){
              if(data.data[i].status == 'develop'){
                apps_list.push({ value: data.data[i].package, label: <span className='text-primary'>{data.data[i].name}</span>})
              }
            }
            for(let i=0; i<data.data.length; i++){
              if(data.data[i].status == 'ban'){
                apps_list.push({ value: data.data[i].package, label: <span className='text-danger'>{data.data[i].name}</span>})
              }
            }

            //for current app
            let current_app = null;
            if(this.props.current != null){
              // console.log(this.props.current)
              for(let i=0; i<apps_list.length; i++){
                if(apps_list[i].value == this.props.current){
                  current_app = apps_list[i];
                }
              }
            }
            // else{
            //   current_app = apps_list[0];
            // }

            this.setState({
              apps_list:apps_list,
              start_apps_list:apps_list,
              current_app:current_app
            })
          }
          else{
              notySend('error', 'error #'+res.error+'\n'+res.info);
          }
      }
    })
  }

  Change = (item:any) => {
    if(this.props.redir == true){
        urlNavigate('/app/'+item.value+'/');
    }
    else{
      if(this.props.onChange != null){
        if(this.props.isMulti){
          this.props.onChange(item);
        }
        else{
          this.props.onChange(item.value);
        }
        
      }
      //for current
      if(this.props.current == null){
        let current_app = null;
        let apps_list = this.state.apps_list; 
        for(let i=0; i<apps_list.length; i++){
          if(apps_list[i].value == item.value){
            this.setState({
              current_app:apps_list[i]
            })
            break
          }
        }
      }
    }
  }

  Filter = (t:string) => {
    let apps_list:any = [];
    // for(let i=0; i<this.state.start_apps_list.length; i++){
      
    // }
    if(t != ''){
      for(let i=0; i<this.state.start_apps_list.length; i++){
        if(String(this.state.start_apps_list[i].label.props.children).toLowerCase().indexOf(t.toLowerCase()) != -1 || String(this.state.start_apps_list[i].value).toLowerCase().indexOf(t.toLowerCase()) != -1){
          apps_list.push(this.state.start_apps_list[i]);
        }
      }
    }
    else{
      apps_list = this.state.start_apps_list;
    }
    // console.log(apps_list)
    this.setState({
      apps_list:apps_list
    })
  }

  CustomFilter = (option:any, searchText:any) => {
    if (
      option.data.label.props.children.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.value.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    if(this.props.isMulti){
      return (
        <Select isMulti={true} onChange={(item)=>{this.Change(item)}} options={this.state.apps_list} />
      );
    }
    else{
      return (
        <Select filterOption={this.CustomFilter} onInputChange={(t)=>{this.Filter(t)}} value={this.state.current_app} onChange={(item)=>{this.Change(item)}} options={this.state.apps_list} />
      );
    }
    
  }
}


