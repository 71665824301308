import React, { Component } from 'react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, encryptAES, getUrlPath, spinner } from '../components/function';
import Loading from '../pages/Loading';
import DevelopersSelect from '../components/DevelopersSelect';
import Select from 'react-select';

export default class ApiKeysPage extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            page_load:false,
            key:''
        }
    }
  
    componentDidMount(){
        this.GetData();
    }


    

    GetData = () => {
        ApiReq('/api_keys/get/').then(res => {
            if(res != false){
                if(res.success == true){
                    this.setState({
                        key:res.data[0].key,
                        page_load:true
                    })
                    
                }
                else{
                    notySend('error', 'error #'+res.error+'\n');
                }
            }
        })
    }

    
    
    Update = () => {
        ApiReq('/api_keys/update/', {key:this.state.key}).then(res => {
            if(res != false){
                if(res.success == true){
                    notySend('success', 'success');
                }
                else{
                    notySend('error', 'error #'+res.error+'\n');
                }
            }
        })
    }
  
    render() {
        return (
            <div className='page-content'>
                 <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <input value="ipwhois.io" className="form-control" disabled={true}></input>
                            </div>
                            <div className="col">
                                <input id="key" onInput={(t)=>{this.setState({key:t.currentTarget.value})}} defaultValue={this.state.key} className="form-control"></input>
                            </div>
                            <div className="col">
                                <button onClick={()=>{this.Update()}} className="btn btn-primary form-control">Update</button>
                            </div>
                        </div>
                        <hr></hr>
                        
                    </div>
                </div>
            </div>
        );
    }
}

