import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, encryptAES, getUrlPath, convertUTCTimeToLocalTime } from '../components/function';
import Loading from '../pages/Loading';


export default class ServiceConnectPage extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            list_table:[],
            page_load:false,
            current_page_tab:'connect_now',
            add_bot_login:'',
            add_bot_pass:'',
        }
    }
  
    componentDidMount(){
        this.GetData('connect_now');
    }


    

    GetData = (type:string) => {
        this.setState({page_load:false})
        ApiReq('/service_connect/get/', {client:(type == 'users_bot')?'bot':'all', type:type}).then(res => {
            if(res != false){
                if(res.success == true){
                    this.setState({
                        list_table:res.data,
                        page_load:true
                    })
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                }
            }
        })
    }

    AddUserBot = () => {
        if(this.state.add_bot_login != '' && this.state.add_bot_pass != ''){
            ApiReq('/service_connect/add_user_bot/', {login_name:encryptAES(this.state.add_bot_login), password:encryptAES(this.state.add_bot_pass)}).then(res => {
                if(res != false){
                    if(res.success == true){
                        notySend('success', 'success');
                        this.GetData('users_bot');
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n');
                    }
                }
            })
        } 
        else{
            notySend('error', 'params login name and password must not be empty!');
        }
    }

    Delete = (id:Number) => {
        ApiReq('/service_connect/delete/', {id:id}).then(res => {
            if(res != false){
                if(res.success == true){
                    notySend('success', 'success');
                    this.GetData('connect_now');
                }
                else{
                    notySend('error', 'error #'+res.error+'\n');
                }
            }
        })
    }

    DeleteUserConnection = () => {
        ApiReq('/service_connect/delete_users/').then(res => {
            if(res != false){
                if(res.success == true){
                    notySend('success', 'success');
                    this.GetData('connect_now');
                }
                else{
                    notySend('error', 'error #'+res.error+'\n');
                }
            }
        })
    }
  
    render() {
        return (
            <div className='page-content'>
                <div style={{borderRadius:'.65rem', borderTopRightRadius:'.65rem', borderTopLeftRadius:'.65rem', marginTop:-1, background:'rgba(108, 117, 125, 1)', marginBottom:5}}>
                    <div className='row' style={{color:'white'}}>
                        <div className='col'>
                            <div onClick={()=>{this.setState({current_page_tab:'connect_now'}); setTimeout(()=>{this.GetData('connect_now')})}} style={{borderRadius:'.65rem', padding:7, display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}} className={(this.state.current_page_tab === 'connect_now')?'bg-primary text-center c_p':'text-center c_p'}>
                                <div style={{marginRight:1}}><i style={{fontSize:15, lineHeight:2}} className="material-icons podcasts">&#xf048;</i></div>
                                <div><span className='text-white'>Connect now</span></div>
                            </div>
                        </div>
                        <div className='col'>
                            <div onClick={()=>{this.setState({current_page_tab:'users_bot'}); setTimeout(()=>{this.GetData('users_bot')})}}  style={{borderRadius:'.65rem', padding:7, display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}} className={(this.state.current_page_tab === 'users_bot')?'bg-primary text-center c_p':'text-center c_p'}>
                                <div style={{marginRight:1}}><i style={{fontSize:15, lineHeight:2}} className="material-icons reddit">&#xeaa0;</i></div>
                                <div><span className='text-white'>Users (bot)</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className='card-body'>
                        {!this.state.page_load?<div className='text-center'><Loading></Loading></div>:
                            <div>
                                {/* {(this.state.current_page_tab === 'connect_now')?null:
                                    <div>
                                        <div className='text-center'><strong>Add user bot form</strong></div>
                                        <div className='row'>
                                            <div className='col'>
                                                <input onInput={(t)=>{this.setState({add_bot_login:t.currentTarget.value})}} placeholder='Write login name' className='form-control m-1'></input>
                                            </div>
                                            <div className='col'>
                                                <input onInput={(t)=>{this.setState({add_bot_pass:t.currentTarget.value})}} placeholder='Write password' className='form-control m-1'></input>
                                            </div>
                                            <div className='col'>
                                                <button onClick={()=>{this.AddUserBot()}} className='btn btn-primary form-control m-1'><i className="material-icons control_point c_p" style={{fontSize:16, margin:4}}></i>Add user bot</button>
                                            </div>
                                        </div>
                                        <hr></hr>
                                    </div>
                                } */}
                                <div className='table-responsive' style={{padding:2}}>
                                    <table className='table table-hover table-bordered text-center'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                {/* <th>Status</th> */}
                                                <th>Login name</th>
                                                <th>Ip</th>
                                                <th>Client</th>
                                                <th>Auth time</th>
                                                <th>Last connect time</th>
                                                {(this.state.current_page_tab === 'connect_now')?<th>Delete</th>:null}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.list_table.map((item:any) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{this.state.list_table.indexOf(item)+1}</td>
                                                        {/* <td>status</td> */}
                                                        <td>{item.login_name}</td>
                                                        <td>{item.ip}</td>
                                                        <td>{item.client}</td>
                                                        <td>{convertUTCTimeToLocalTime(item.auth_time)}</td>
                                                        <td>{convertUTCTimeToLocalTime(item.last_connect_time)}</td>
                                                        {(this.state.current_page_tab === 'connect_now')?<td><button onClick={()=>{this.Delete(item.id)}} className='btn btn-danger'>Delete</button></td>:null}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                {(this.state.current_page_tab === 'connect_now')?<button onClick={()=>{this.DeleteUserConnection()}} className='btn btn-danger form-control'>Delete user connections</button>:null}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

