import React, { Component } from 'react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, getJsonFromUrl, getUrlPath, spinner } from '../components/function';
import Loading from '../pages/Loading';
import DevelopersSelect from '../components/DevelopersSelect';
import Select from 'react-select';

export default class SipcPage extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            page_load:false,
            section_creater_parser:true,
            section_tester:false,
            parser_url:'',
            create_url:'',
            params_list:[],
            tester_url:'',
            show_result:false,
            show_result_sipc_checkip:false,
            sipc_checkip_url:getApiDomain()+'/sipc/check_ip/'
        }
    }
  
    componentDidMount(){
       
    }


    

    GetData = () => {
        ApiReq('/api_keys/get/').then(res => {
            if(res != false){
                if(res.success == true){
                    this.setState({
                        key:res.data[0].key,
                        page_load:true
                    })
                    
                }
                else{
                    notySend('error', 'error #'+res.error+'\n');
                }
            }
        })
    }

    CreateUrl = () => {
        this.setState({
            create_url:'',
            show_result:false,
            show_result_sipc_checkip:false,
        })
        let url = 'https://api.workhardplayeasy.pro/api/sipc/add/?'
        let params_list = this.state.params_list;
        for(let i = 0; i<params_list.length; i++){
            let val = String(Object.values(params_list[i]));
            if(val.indexOf('http') !== -1){
                val = encodeURIComponent(val);
            }
            url = url+String(Object.getOwnPropertyNames(params_list[i]))+'='+val+'&';
        }
        this.setState({
            create_url:url.substring(0, url.length - 1),
            tester_url:url.substring(0, url.length - 1),
        })
    }

    AddParam = () => {
        let params_list = this.state.params_list;
        let newParam:any = {};
        newParam['name'+params_list.length] = '';
        params_list.push(newParam);
        this.setState({
            params_list:params_list
        })
    }

    UpdateParam = (index:number, key:string, value:string) => {
        let params_list = this.state.params_list;
        let uParam:any = {};
        uParam[key] = value;
        params_list[index] = uParam;
        this.setState({
            params_list:params_list
        })
    }

    DeleteParam = (index:number) => {
        let params_list = this.state.params_list;
        params_list.splice(index, 1)
        this.setState({
            params_list:params_list
        })
    }
    
    ParseUrl = () => {
        if(this.state.parser_url !== ''){
            let parser_url = this.state.parser_url;
            let params_list:any = [];
            for (const [key, value] of Object.entries(getJsonFromUrl(parser_url))) {
                let param:any = {};
                param[key] = value;
                params_list.push(param);
            }
            this.setState({
                params_list:params_list
            })
            notySend('success', 'success');
            
        }
        else{
            notySend('error', 'write correct url and try again');
        }
    }

    RunTester = () => {
        this.setState({
            show_result:false,
            show_result_sipc_checkip:false,
        })
        setTimeout(() => {
            this.setState({
                show_result:true
            })
        }, 1000)
    }
  
    render() {
        return (
            <div className='page-content'>
                 <div className="card">
                    <div className="card-body">
                        <h6>SIPC - system ip conversions</h6>
                        <div className="card">
                            <div className="card-header c_p" style={{borderBottom:'none'}} onClick={()=>{this.setState({section_creater_parser:!this.state.section_creater_parser})}}>
                                <a className="card-link" data-toggle="collapse" >
                                    <strong>Creater / parser</strong>
                                </a>
                            </div>
                            <div  className={this.state.section_creater_parser?"collapse show":"collapse"}>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label>Parser - write url and click Parse button</label>
                                        <input onInput={(t)=>{this.setState({parser_url:t.currentTarget.value})}} className='form-control' placeholder='Write url for parsing params'></input>
                                        <hr></hr>
                                        <label>Parameters list </label> 
                                        <i onClick={()=>{this.AddParam()}} style={{fontSize:20, marginLeft:5, position:'absolute'}} className="material-icons control_point text-primary c_p">&#xe3ba;</i>
                                        <div className='table-responsive'>
                                            {this.state.params_list.map((item:any) => {
                                                return (
                                                <div key={this.state.params_list.indexOf(item)} style={{padding:5}}>
                                                        <div className='row'>
                                                            <div className='col-5'>
                                                                <input onInput={(t)=>{this.UpdateParam(this.state.params_list.indexOf(item), t.currentTarget.value, this.state.params_list[this.state.params_list.indexOf(item)][String(Object.getOwnPropertyNames(item))])}} value={Object.getOwnPropertyNames(item)} className='form-control' placeholder='parameter name'></input>
                                                            </div>
                                                            <div className='col-5'>
                                                                <input onInput={(t)=>{this.UpdateParam(this.state.params_list.indexOf(item), String(Object.getOwnPropertyNames(item)), t.currentTarget.value)}} value={Object.values(item)} className='form-control' placeholder='parameter value'></input>
                                                            </div>
                                                            <div className='col-2'>
                                                                <button onClick={()=>{this.DeleteParam(this.state.params_list.indexOf(item))}} className='btn btn-outline-danger form-control'>delete</button>
                                                            </div>
                                                        </div>
                                                </div>
                                                )
                                            })}
                                        </div>
                                        {
                                            (this.state.create_url === '')?
                                                null
                                            :
                                                <><hr></hr><label>Result</label><input disabled={true} value={this.state.create_url} autoFocus={true} className='form-control is-valid'></input></>
                                        }
                                        <hr></hr>
                                        <div className='row'>
                                            <div className='col'>
                                                <button onClick={()=>{this.CreateUrl()}} className='btn btn-outline-primary form-control'>Create</button>
                                            </div>
                                            <div className='col'>
                                                <button onClick={()=>{this.ParseUrl()}} className='btn btn-outline-info form-control'>Parse</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header c_p" style={{borderBottom:'none'}} onClick={()=>{this.setState({section_tester:!this.state.section_tester})}}>
                                <a className="card-link" data-toggle="collapse" >
                                    <strong>Sipc tester</strong>
                                </a>
                            </div>
                            <div  className={this.state.section_tester?"collapse show":"collapse"}>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-sm-8'>
                                            <label>Url</label>
                                            <input value={this.state.tester_url} onInput={(t)=>{this.setState({tester_url:t.currentTarget.value})}} className='form-control'></input>
                                        </div>
                                        <div className='col-sm-4'>
                                            <label>Run</label>
                                            <button onClick={()=>{this.RunTester()}} className='btn btn-outline-primary form-control'>Run</button>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    {
                                        this.state.show_result? 
                                            <div className='row'>
                                                <div className='col'>
                                                    <iframe onLoad={()=>{setTimeout(()=>{this.setState({show_result_sipc_checkip:true})}, 1000)}} style={{width:'100%', minHeight:500}} src={this.state.tester_url}></iframe>
                                                </div>
                                                <div className='col'>
                                                    {
                                                        this.state.show_result_sipc_checkip?
                                                            <iframe style={{width:'100%', minHeight:500}} src={this.state.sipc_checkip_url}></iframe>
                                                        :
                                                            <div className='text-center'>
                                                                {spinner()}
                                                            </div>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        :
                                            null
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

