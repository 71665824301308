import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.min.css';
// import * as serviceWorker from '.serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <App />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA


// serviceWorkerRegistration.register();

// caches.keys().then(function(keys) {
//   keys.forEach(function(key) {
//     caches.delete(key);
//   });
// });

// self.addEventListener('fetch', function(event:any) {
//   // Игнорируем кэширование для всех запросов
//   event.respondWith(fetch(event.request));
// });


reportWebVitals();

