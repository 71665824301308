import React, { Component } from 'react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, encryptAES, getUrlPath } from '../components/function';
import Loading from '../pages/Loading';


export default class IntegrationPage extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            page_load:false,
            organic_body:JSON.stringify({"app":'com.win.pushservice',"hash":"1608823819227-6410566072403469242","deeplink":null,"gaid": "9cb623b4-240e-4e42-a56d-d1a77eaa0967","data":{"is_first_launch":true,"install_time":"2021-02-02 18:24:13.206","af_message":"organic install","af_status":"Organic"}, "device_info": {"charging": false}}, undefined, 4),
            non_organic_body:JSON.stringify({"app":'com.win.pushservice',"hash":"1608823819227-6410566072403469242","deeplink":null,"gaid": "9cb623b4-240e-4e42-a56d-d1a77eaa0967","data":{"adgroup_id":"23849030067510613","retargeting_conversion_type":"none","is_fb":true,"is_first_launch":true,"iscache":false,"click_time":"2021-09-17 10:41:08.000","match_type":"srn","adset":"cz1","af_channel":"Facebook","is_paid":true,"campaign_id":"23849030066530613","install_time":"2021-09-17 10:41:47.043","media_source":"Facebook Ads","agency":null,"af_siteid":null,"af_status":"Non-organic","af_sub1":null,"ad_id":"23849030077850613","af_sub5":null,"af_sub4":null,"af_sub3":null,"af_sub2":null,"adset_id":"23849030066840613","campaign":"test1_test22_test3_test4","http_referrer":null,"is_mobile_data_terms_signed":true,"adgroup":"c1"}, "device_info": {"charging": false}}, undefined, 4),
            response:'{"success":true, "url":"https://google.com"}'
        }
    }
  
    componentDidMount(){
        //this.GetData('connect_now');
    }


    

    GetData = (type:string) => {
        this.setState({page_load:false})
        ApiReq('/service_connect/get/', {client:(type == 'users_bot')?'bot':'all', type:type}).then(res => {
            if(res != false){
                if(res.success == true){
                    this.setState({
                        list_table:res.data,
                        page_load:true
                    })
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                }
            }
        })
    }

    
  
    render() {
        return (
            <div className='page-content'>
                {/* <div style={{borderRadius:'.65rem', borderTopRightRadius:'.65rem', borderTopLeftRadius:'.65rem', marginTop:-1, background:'rgba(108, 117, 125, 1)', marginBottom:5}}>
                    <div className='row' style={{color:'white'}}>
                        <div className='col'>
                            <div onClick={()=>{this.setState({current_page_tab:'connect_now'}); setTimeout(()=>{this.GetData('connect_now')})}} style={{borderRadius:'.65rem', padding:7, display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}} className={(this.state.current_page_tab === 'connect_now')?'bg-primary text-center c_p':'text-center c_p'}>
                                <div style={{marginRight:1}}><i style={{fontSize:15, lineHeight:2}} className="material-icons podcasts">&#xf048;</i></div>
                                <div><span className='text-white'>Connect now</span></div>
                            </div>
                        </div>
                        <div className='col'>
                            <div onClick={()=>{this.setState({current_page_tab:'users_bot'}); setTimeout(()=>{this.GetData('users_bot')})}}  style={{borderRadius:'.65rem', padding:7, display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}} className={(this.state.current_page_tab === 'users_bot')?'bg-primary text-center c_p':'text-center c_p'}>
                                <div style={{marginRight:1}}><i style={{fontSize:15, lineHeight:2}} className="material-icons reddit">&#xeaa0;</i></div>
                                <div><span className='text-white'>Users (bot)</span></div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="card">
                    <div className='card-body'>
                        <h3 className='text-center'>Application integration documentation</h3>
                        <div  style={{padding:2}}>
                            <table className='table table-hover table-bordered text-center'>
                                <thead>
                                    <tr>
                                        <th>Param</th>
                                        <th>Type</th>
                                        <th>Rule to param</th>
                                        <th>Description</th>
                                        <th>Example</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>hash</td>
                                        <td>string</td>
                                        <td>required</td>
                                        <td>Unique installation identifier, if a tracker is used in the application, the identifier from the tracker is use</td>
                                        <td>1708280634565-4501989379356555598</td>
                                    </tr>
                                    <tr>
                                        <td>app</td>
                                        <td>string or number</td>
                                        <td>required</td>
                                        <td>A unique application identifier, in Android applications the package name is a string of this type com.exemple, and in iOS applications it is app id from appstore connect but use only numbers. (string on adnroid and number on ios)</td>
                                        <td>com.fireway.goldenlepr</td>
                                    </tr>
                                    <tr>
                                        <td>data</td>
                                        <td>json array</td>
                                        <td>required</td>
                                        <td>{'Installation data from the tracker, if the application does not use a tracker, then you can add a constant array of organics from the tracker or you can add a parameter there {tracker:false}'}</td>
                                        <td>{`"data": {
                                                "is_first_launch": true,
                                                "install_time": "2021-02-02 18:24:13.206",
                                                "af_message": "organic install",
                                                "af_status": "Organic"
                                            }`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>gaid</td>
                                        <td>string</td>
                                        <td>optional</td>
                                        <td>Device advertising identifier (Google Advertising ID in android and IDFA in ios)</td>
                                        <td>9cb623b4-240e-4e42-a56d-d1a77eaa0967</td>
                                    </tr>
                                    <tr>
                                        <td>deeplink</td>
                                        <td>string</td>
                                        <td>optional</td>
                                        <td>Deeplink from facebook (this param returned from facebook lib in app code)</td>
                                        <td>app://naming</td>
                                    </tr>
                                    <tr>
                                        <td>device_info</td>
                                        <td>json array</td>
                                        <td>optional</td>
                                        <td>Param for device information, now we add in this array state of charging, if device connect to charging param charging set to true or false if charging off</td>
                                        <td>{`"device_info": {
                                                "charging": false
                                            }`}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <p className='text-center'><strong>Example body (organic)</strong></p>
                                <textarea disabled={true} value={this.state.organic_body} style={{minHeight:400, color:'black'}} className='form-control m-1'></textarea>
                            </div>
                            <div className='col'>
                                <p className='text-center'><strong>Example body (non-organic)</strong></p>
                                <textarea disabled={true} value={this.state.non_organic_body} style={{minHeight:400, color:'black'}} className='form-control m-1'></textarea>
                            </div>
                        </div>
                        <div>
                            <p className='text-center'><strong>Example response</strong></p>
                            <p className='text-center'><strong className='text-danger'>(if response have success:true app need go to url from response or response have success:false app need go to game)</strong></p>
                            <input disabled={true} value={this.state.response} style={{color:'black'}} className='form-control m-1'></input>
                        </div>
                        <hr></hr>
                        {/* <div className='row'>
                            <div className='col'>
                               <button className='btn btn-outline-primary form-control'>Run organic request</button>
                            </div>
                            <div className='col'>
                                
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

