import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, urlNavigate, getUrlPath } from '../components/function';
import Loading from '../pages/Loading';
import CustomModal from '../components/CustomModal';

export default class BlackListIpPage extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            list_table:[],
            page_load:false,
            add_ip:'',
            modal:false
        }
    }
  
    componentDidMount(){
        this.GetData();
    }

    GetData = () => {
        ApiReq('/black_list_ip/get_list/').then(res => {
            if(res != false){
                if(res.success == true){
                    this.setState({
                        list_table:res.data,
                        page_load:true
                    })
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                }
            }
        })
    }

    Add = () => {
        if(this.state.add_ip != ''){
            ApiReq('/black_list_ip/add/', {ip:this.state.add_ip}).then(res => {
                if(res != false){
                    if(res.success == true){
                        notySend('success', 'success');
                        this.GetData();
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n');
                    }
                }
            })
        } 
        else{
            notySend('error', 'param ip must not be empty!');
        }
    }

    Delete = (id:Number) => {
        ApiReq('/black_list_ip/delete/', {id:id}).then(res => {
            if(res != false){
                if(res.success == true){
                    notySend('success', 'success');
                    this.GetData();
                }
                else{
                    notySend('error', 'error #'+res.error+'\n');
                }
            }
        })
    }
  
    render() {
        if(!this.state.page_load){
            return(
                <div className='page-content'>
                    <div className="card">
                        <div className='card-body'>
                            <div className='text-center'><Loading></Loading></div>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return (
                <div className='page-content'>
                    <div className="card">
                        <div className='card-body'>
                            <div style={{textAlign:'right'}}>
                                <button onClick={()=>{this.setState({modal:true})}} className="btn btn-primary"><i className="material-icons control_point c_p" style={{fontSize:16, margin:4}}></i>Add ip to black list</button>
                            </div>
                            <hr></hr>
                            <div className='table-responsive' style={{padding:2}}>
                                <table className='table table-hover table-bordered text-center'>
                                    <thead>
                                        <tr>
                                            <th>Ip</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.list_table.map((item:any) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{item.ip}</td>
                                                    <td><button onClick={()=>{this.Delete(item.id)}} className='btn btn-danger'>Delete</button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <CustomModal visible={this.state.modal} header={'Add'} callback={()=>{this.setState({modal:false})}}>
                        <div className='form-group'>
                        <label>Ip</label>
                        <input value={this.state.add_ip} onInput={(t)=>{this.setState({add_ip:t.currentTarget.value})}} className='form-control'></input>
                        <hr></hr>
                        <button onClick={()=>{this.Add()}} className="btn btn-primary form-control"><i className="material-icons control_point c_p" style={{fontSize:16, margin:4}}></i>Add</button>
                        </div>
                    </CustomModal>
                </div>
            );
        }
    }
}

