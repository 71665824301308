import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate, getClientConfig, clientDecryptAES, clientEncryptAES, setUserData, encryptAES } from '../components/function';
export default class LoginPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        remember:false,
        login:'',
        password:'',
        page_load:true,
      }
    }
  
    componentDidMount(){
      if(localStorage.getItem('user_login_data') != null){
        let login_data:any = localStorage.getItem('user_login_data');
        login_data = JSON.parse(login_data);
        login_data.login_name = clientDecryptAES(login_data.login);
        login_data.password = clientDecryptAES(login_data.password);
        this.setState({
          login:login_data.login_name,
          password:login_data.password,
        })
      }
    }

    SetRemember = () => {
      if(this.state.remember == false){
        this.setState({
          remember:true
        })
      }
      else{
        this.setState({
          remember:false
        })
      }
    }
  
    Login = () => {
      if(this.state.login != '' && this.state.password != ''){
        this.setState({
          page_load:false
        })
        let encryptLoginName = clientEncryptAES(this.state.login);
        let encryptPassword = clientEncryptAES(this.state.password);
        if(this.state.remember == true){
          localStorage.setItem('user_login_data', JSON.stringify({login:encryptLoginName, password:encryptPassword}))
        }
        let client = 'user';
        if(['alarm_bot_secret', '@12!SyncSwap12!@', 'deepnaming_login'].indexOf(this.state.login) != -1){
          client = 'bot';
        }
        
        ApiReq('/auth/login/', {login_name:encryptAES(this.state.login), password:encryptAES(this.state.password), client:client}).then(res => {
          if(res != false){
            if(res.success == true){
              setUserData(true, this.state.login, Date.now(), res.roles, res.auth_token, res.team);
              urlNavigate('/home/');
            }
            else{
              this.setState({
                page_load:true
              })
              notySend('error', 'error #'+res.error+'\n')
            }
          }
        })
       
      }
      else{
        notySend('error', 'email and password must not be empty!');
      }
    }

    CheckSettingModal = () => {
      ApiReq('/system_config/get_db_config').then(res => {
        if(res != false){
            if(res.connect == false){
              this.setState({
                setting_modal:true
              })
            }
        }
      })
    }
  
    render() {
      return (
        <main className="authentication-content">
          <div className="container-fluid">
            <div className="authentication-card">
              <div className="card">
                <div className="card-body p-4 p-sm-5 " style={{maxWidth:450}}>
                  <div className='card bg-dark' style={{alignItems:'center'}}>
                    <img src={getClientConfig('logo')} style={{width:'40%'}}></img>
                  </div>
                    <div className="form-body">
                      <div className="row g-3">
                        <div className="col-12">
                          <label  className="form-label">Email</label>
                          <div className="ms-auto position-relative">
                            <div className="position-absolute top-50 translate-middle-y search-icon px-3 "><i className="material-icons local_post_office icon-input">&#xe554;</i></div>
                            <input defaultValue={this.state.login} onInput={(e)=>{this.setState({login:e.currentTarget.value})}} style={{borderRadius:50}} className="form-control  ps-5" placeholder="Write email"></input>
                          </div>
                        </div>
                        <div className="col-12">
                          <label  className="form-label">Password</label>
                          <div className="ms-auto position-relative">
                            <div className="position-absolute top-50 translate-middle-y search-icon px-3 "><i className="material-icons https icon-input">&#xe88d;</i></div>
                            <input defaultValue={this.state.password} onInput={(e)=>{this.setState({password:e.currentTarget.value})}} type="password" style={{borderRadius:50}} className="form-control radius-30 ps-5" placeholder="Write password"></input>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-check form-switch">
                            <label className="form-check-label">
                              <input checked={this.state.remember} onChange={()=>{this.SetRemember()}}  type="checkbox" className="form-check-input"></input>Remember me
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-grid">
                            <button onClick={()=>{this.Login()}} style={{borderRadius:50}} disabled={this.state.page_load?false:true} className="btn btn-primary radius-30">{(this.state.page_load == false) ? <div className="spinner-border text-warning spinner-border-sm"></div>:null} Login</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </main>
      );
    }
}


